import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { format } from 'd3'

const BarChart = ({ data }) => {
  const chartRef = useRef()
  const formatter = format('.3~s')

  useEffect(() => {
    const createChart = () => {
      d3.select(chartRef.current).selectAll('*').remove()

      const margin = { top: 20, right: 90, bottom: 50, left: 120 }
      const containerWidth = chartRef.current.offsetWidth
      const width = containerWidth - margin.left - margin.right
      const height = data.length * 100

      const xScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, d => d.value)])
        .range([0, width])

      const yScale = d3
        .scaleBand()
        .domain(data.map(d => d.name))
        .range([0, height])
        .padding(0.6)

      const svg = d3.select(chartRef.current).append('svg').attr('width', containerWidth).attr('height', height)

      const gridLines = svg
        .selectAll('.grid-line')
        .data(xScale.ticks(chartRef.current.offsetWidth > 478 ? 5 : 3))
        .enter()
        .append('g')
        .attr('class', 'grid-line')

      gridLines
        .append('line')
        .attr('x1', d => margin.left + xScale(d))
        .attr('y1', 30)
        .attr('x2', d => margin.left + xScale(d))
        .attr('y2', height - 30)
        .style('stroke', '#ddd')
        .style('stroke-width', 1)
        .style('stroke-dasharray', '4')

      gridLines
        .append('text')
        .attr('x', d => margin.left + xScale(d))
        .attr('y', 20)
        .text(d => (d === 0 ? 0 : d >= 1 ? formatter(d) : d.toFixed(3)))
        .attr('text-anchor', 'middle')
        .style('fill', '#253243')
        .style('font-weight', '600')
        .style('font-family', 'Poppins')
        .style('font-size', '11px')

      svg
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', margin.left)
        .attr('y', d => yScale(d.name))
        .attr('width', 0)
        .attr('height', '42px')
        .attr('fill', '#BAB6F5')
        .transition()
        .duration(1000)
        .delay((d, i) => i * 100)
        .attr('width', d => xScale(d.value))

      svg
        .selectAll('.bar-label')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'bar-label')
        .attr('transform', d => `translate(${margin.left},${yScale(d.name) + yScale.bandwidth() / 2})`)
        .append('text')
        .attr('x', -110)
        .attr('y', 0)
        .text(d => d.name)
        .attr('alignment-baseline', 'middle')
        .style('fill', '#253243')
        .style('font-weight', '600')
        .style('font-family', 'Poppins')
        .style('font-size', '13px')

      svg
        .selectAll('.value-label')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'value-label')
        .attr('x', d => margin.left + xScale(d.value) + 5)
        .attr('y', d => yScale(d.name) + yScale.bandwidth() / 1.5)
        .attr('alignment-baseline', 'middle')
        .text(d => (d?.value === 0 ? 0 : d?.value >= 1 ? formatter(d?.value) : d?.value?.toFixed(3)) + ' tCO₂')
        .style('fill', '#253243')
        .style('font-weight', '600')
        .style('font-family', 'Poppins')
        .style('font-size', '13px')
    }

    createChart()

    window.addEventListener('resize', createChart)

    return () => {
      window.removeEventListener('resize', createChart)
    }
  }, [data])

  return <div ref={chartRef}></div>
}

BarChart.propTypes = {
  data: PropTypes.array,
}

export default BarChart
