import React from 'react'
import { AppBar, Grid, Toolbar, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { AppStyles } from './AppStyles'
import { ReactComponent as HeaderRightIcon } from '../../utils/icons/HeaderRightIcon.svg'
import { useApp } from './AppHook'
import StatsAndFilters from '../StatsAndFilters/StatsAndFilters'
import Co2RacapPage from '../Co2RacapPage/Co2RacapPage'
import BegesPage from '../BegesPage/BegesPage'
import GhgPage from '../GhgPage/GhgPage'

const GlobalContainer = styled(Grid)(AppStyles.globalContainer)
const StyledToolbar = styled(Toolbar)(AppStyles.toolbarStyle)
const StyledAppBar = styled(AppBar)(AppStyles.appBarStyle)

const App = () => {
  const {
    selectedTab,
    setSelectedTab,
    isSelectedTab,
    filtredCO2RECAPData,
    filtredPosts,
    stats,
    begesData,
    ghgData,
    filters,
  } = useApp()
  return (
    <GlobalContainer container>
      <StyledAppBar position="fixed" elevation={1}>
        <StyledToolbar disableGutters>
          <Grid container justifyContent={'space-between'} height={'100%'}>
            <Grid item display={'flex'} alignItems={'center'} sx={{ marginLeft: '10px' }}>
              <img src="logoEkyoM.png" alt="Logo Ekyo" style={{ height: '46px' }} />
              <Grid container sx={{ marginLeft: '7px', width: '90px' }}>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      lineHeight: '18px',
                      color: '#444444',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    Mesure
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      lineHeight: '13px',
                      color: '#565656',
                      fontSize: '11px',
                      fontWeight: 400,
                    }}
                  >
                    Èvaluer votre impakt carbone
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display={'flex'} alignItems={'flex-end'}>
              <HeaderRightIcon />
            </Grid>
          </Grid>
        </StyledToolbar>
        <Grid
          item
          container
          alignContent={'center'}
          justifyContent={'center'}
          sx={{
            minHeight: '55px',
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Grid
            item
            container
            alignContent={'center'}
            justifyContent={'center'}
            onClick={() => {
              filters.initializeFilters()
              !isSelectedTab(1) && setSelectedTab(1)
            }}
            sx={{
              cursor: 'pointer',
              margin: '0px 50px',
              height: '33px',
              width: '187px',
              borderRadius: '8px',
              backgroundColor: selectedTab === 1 ? '#F2F0FD' : '#FFFFFF',
            }}
          >
            <Typography
              sx={{
                color: isSelectedTab(1) ? '#484095' : '#253243',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Format : RECAP CO₂
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignContent={'center'}
            justifyContent={'center'}
            onClick={() => {
              filters.initializeFilters()
              !isSelectedTab(2) && setSelectedTab(2)
            }}
            sx={{
              cursor: 'pointer',
              margin: '0px 50px',
              height: '33px',
              width: '187px',
              borderRadius: '8px',
              backgroundColor: selectedTab === 2 ? '#F2F0FD' : '#FFFFFF',
            }}
          >
            <Typography
              sx={{
                color: isSelectedTab(2) ? '#484095' : '#253243',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Format : BEGES V5
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignContent={'center'}
            justifyContent={'center'}
            onClick={() => {
              filters.initializeFilters()
              !isSelectedTab(3) && setSelectedTab(3)
            }}
            sx={{
              cursor: 'pointer',
              margin: '0px 50px',
              height: '33px',
              width: '187px',
              borderRadius: '8px',
              backgroundColor: selectedTab === 3 ? '#F2F0FD' : '#FFFFFF',
            }}
          >
            <Typography
              sx={{
                color: isSelectedTab(3) ? '#484095' : '#253243',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Format : GHG Protocol
            </Typography>
          </Grid>
        </Grid>
      </StyledAppBar>
      <Grid container alignContent={'flex-start'} sx={{ marginTop: { xs: '160px', md: '130px' }, width: '100%' }}>
        {isSelectedTab(1) && (
          <>
            <StatsAndFilters stats={stats.co2RacapStats} format={'co2Recap'} filters={filters} />
            <Co2RacapPage filtredCO2RECAPData={filtredCO2RECAPData} filtredPosts={filtredPosts} />
          </>
        )}
        {isSelectedTab(2) && (
          <>
            <StatsAndFilters stats={stats.begesStats} format={'begesV5'} filters={filters} />
            <BegesPage
              filtredBegesData={begesData.filtredBegesData}
              filtredOraganisations={begesData.begesFiltredOraganisations}
              filtredCategories={begesData.begesFiltredCategories}
            />
          </>
        )}
        {isSelectedTab(3) && (
          <>
            <StatsAndFilters stats={stats.ghgStats} format={'ghg'} filters={filters} />
            <GhgPage filtredGhgData={ghgData.filtredGhgData} filtredScopes={ghgData.ghgFiltredScopes} />
          </>
        )}
      </Grid>
    </GlobalContainer>
  )
}
export default App
