export const CO2RECAP = [
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 173342,
    uncertainty: 0.27,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 6413,
    uncertainty: 0.11,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 343811,
    uncertainty: 0.11,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 2569641,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 90244,
    uncertainty: 0.34,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 0,
    uncertainty: 0.0,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 994835,
    uncertainty: 0.2,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 5438664,
    uncertainty: 0.25,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 136,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 902,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 378082,
    uncertainty: 0.14,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 74,
    uncertainty: 0.2,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Utilisation',
      id: '1',
    },
    subpost: {
      name: "Consommation d'électricité pour l'utilisation",
      id: '1',
    },
    result: 26203,
    uncertainty: 0.09,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 5500,
    uncertainty: 0.51,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 120000,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 17820,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 204118,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 14100,
    uncertainty: 0.51,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 31,
    uncertainty: 0.61,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 2124000,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 83,
    uncertainty: 0.6,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 63840,
    uncertainty: 1.12,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 216678,
    uncertainty: 0.04,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 8020,
    uncertainty: 0.04,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 364815,
    uncertainty: 0.11,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 3540611,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 1475898,
    uncertainty: 0.21,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 8497913,
    uncertainty: 0.25,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 112805,
    uncertainty: 0.34,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 0,
    uncertainty: 0.0,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 170,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 464887,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 605880,
    uncertainty: 0.15,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 92,
    uncertainty: 0.2,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Utilisation',
      id: '1',
    },
    subpost: {
      name: "Consommation d'électricité pour l'utilisation",
      id: '1',
    },
    result: 9981,
    uncertainty: 0.09,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 5500,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 22275,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 255008,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 2655000,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 79800,
    uncertainty: 1.12,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 43733,
    uncertainty: 0.55,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 223463,
    uncertainty: 0.6,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret sortant',
      id: '1',
    },
    result: 142200,
    uncertainty: 0.68,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret sortant',
      id: '1',
    },
    result: 293063,
    uncertainty: 0.55,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 111284,
    uncertainty: 0.51,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: "Achats d'électricité",
      id: '1',
    },
    result: 119146,
    uncertainty: 0.26,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 0,
    uncertainty: 0.83,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 2843,
    uncertainty: 0.26,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 4590,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 25290,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 1692,
    uncertainty: 0.51,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 10620,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 25935,
    uncertainty: 1.12,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 1,
    uncertainty: 0.56,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 2,
    uncertainty: 0.6,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 27500,
    uncertainty: 0.57,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 2400000,
    uncertainty: 0.84,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 99620,
    uncertainty: 0.34,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 0,
    uncertainty: 0.0,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 208370,
    uncertainty: 0.18,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 898913,
    uncertainty: 0.33,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Utilisation',
      id: '1',
    },
    subpost: {
      name: "Consommation d'électricité pour l'utilisation",
      id: '1',
    },
    result: 561,
    uncertainty: 0.21,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 821,
    uncertainty: 0.14,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 4411037,
    uncertainty: 0.14,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 16646,
    uncertainty: 0.28,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 48069,
    uncertainty: 0.34,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 2550000,
    uncertainty: 0.82,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 40200,
    uncertainty: 0.32,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Séville',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 89535,
    uncertainty: 0.28,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 9849,
    uncertainty: 0.04,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 4374,
    uncertainty: 0.04,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 187533,
    uncertainty: 0.11,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Métaux',
      id: '1',
    },
    result: 702270,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 804100,
    uncertainty: 0.21,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 4578000,
    uncertainty: 0.25,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 61530,
    uncertainty: 0.34,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 0,
    uncertainty: 0.0,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 170,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 253575,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Futurs emballages',
      id: '1',
    },
    subpost: {
      name: 'Plastiques',
      id: '1',
    },
    result: 247500,
    uncertainty: 0.2,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 54,
    uncertainty: 0.2,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 0,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 0,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 12150,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 140500,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 56400,
    uncertainty: 0.51,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 1593000,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fin de vie',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 39900,
    uncertainty: 1.12,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 224570,
    uncertainty: 0.55,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Fret',
      id: '1',
    },
    subpost: {
      name: 'Fret entrant',
      id: '1',
    },
    result: 121889,
    uncertainty: 0.6,
    labels: [],
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'Pays;Site;Site production',
    },
    poste: {
      name: 'Utilisation',
      id: '1',
    },
    subpost: {
      name: "Consommation d'électricité pour l'utilisation",
      id: '1',
    },
    result: 1786561,
    uncertainty: 0.47,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 2040,
    uncertainty: 0.13,
    labels: ['Chauffage'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: "Achats d'électricité",
      id: '1',
    },
    result: 618,
    uncertainty: 0.14,
    labels: ['Télétravail'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 2138,
    uncertainty: 0.14,
    labels: ['Télétravail'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements domicile-travail',
      id: '1',
    },
    result: 3798,
    uncertainty: 0.46,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements domicile-travail',
      id: '1',
    },
    result: 63,
    uncertainty: 0.61,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements des employés dans le cadre du travail',
      id: '1',
    },
    result: 38745,
    uncertainty: 0.07,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements des employés dans le cadre du travail',
      id: '1',
    },
    result: 15,
    uncertainty: 0.23,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements domicile-travail',
      id: '1',
    },
    result: 5705,
    uncertainty: 0.46,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements des employés dans le cadre du travail',
      id: '1',
    },
    result: 1322,
    uncertainty: 0.46,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Bâtiments',
      id: '1',
    },
    result: 15167,
    uncertainty: 0.52,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 540,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Routes, voies ferrées, parkings, ports et infrastructures diverses',
      id: '1',
    },
    result: 876,
    uncertainty: 0.26,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 3045,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 17188,
    uncertainty: 0.66,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Bâtiments',
      id: '1',
    },
    result: 14000,
    uncertainty: 0.5,
    labels: ['Télétravail'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Bâtiments',
      id: '1',
    },
    result: 8500,
    uncertainty: 0.52,
    labels: ['Télétravail'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Eaux usées',
      id: '1',
    },
    result: 66,
    uncertainty: 0.11,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 279500,
    uncertainty: 0.36,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Nourriture, repas & boissons',
      id: '1',
    },
    result: 3570,
    uncertainty: 0.57,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Nourriture, repas & boissons',
      id: '1',
    },
    result: 141570,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: "Achats d'électricité",
      id: '1',
    },
    result: 618296,
    uncertainty: 0.15,
    labels: ['Chauffage'],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 245110,
    uncertainty: 0.58,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 12535,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Hors énergie',
      id: '1',
    },
    subpost: {
      name: 'Emissions de gaz hors Kyoto',
      id: '1',
    },
    result: 309415,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Espagne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 1145,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Nourriture, repas & boissons',
      id: '1',
    },
    result: 867,
    uncertainty: 0.57,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Nourriture, repas & boissons',
      id: '1',
    },
    result: 3630,
    uncertainty: 0.52,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 687,
    uncertainty: 0.15,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 215000,
    uncertainty: 0.36,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Routes, voies ferrées, parkings, ports et infrastructures diverses',
      id: '1',
    },
    result: 3650,
    uncertainty: 0.26,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 55000,
    uncertainty: 0.66,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 3713,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 745,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 3248,
    uncertainty: 0.54,
    labels: [],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 2195,
    uncertainty: 0.26,
    labels: ['Chauffage'],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: "Achats d'électricité",
      id: '1',
    },
    result: 25762,
    uncertainty: 0.14,
    labels: ['Chauffage'],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: "Achats d'électricité",
      id: '1',
    },
    result: 17776,
    uncertainty: 0.14,
    labels: ['Télétravail'],
  },
  {
    orga: {
      name: 'Pologne',
      id: 'Pays',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Bâtiments',
      id: '1',
    },
    result: 0,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 229,
    uncertainty: 0.05,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 134400,
    uncertainty: 0.8,
    labels: ['Support'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 10240,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 3080,
    uncertainty: 0.8,
    labels: ['Support'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Utilisation de ratios monétaires',
      id: '1',
    },
    result: 6490,
    uncertainty: 0.8,
    labels: ['Support'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Finance',
      id: '1',
    },
    subpost: {
      name: 'Trésorerie',
      id: '1',
    },
    result: 70600,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Finance',
      id: '1',
    },
    subpost: {
      name: 'Investissements',
      id: '1',
    },
    result: 0,
    uncertainty: 0.8,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Bâtiments',
      id: '1',
    },
    result: 5417,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 381,
    uncertainty: 0.5,
    labels: ['Numérique'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 194,
    uncertainty: 0.5,
    labels: ['Numérique', 'Télétravail'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Routes, voies ferrées, parkings, ports et infrastructures diverses',
      id: '1',
    },
    result: 88,
    uncertainty: 0.26,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Déchets banals',
      id: '1',
    },
    result: 7720,
    uncertainty: 0.58,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déchets directs',
      id: '1',
    },
    subpost: {
      name: 'Eaux usées',
      id: '1',
    },
    result: 39,
    uncertainty: 0.11,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements domicile-travail',
      id: '1',
    },
    result: 2234,
    uncertainty: 0.45,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements des employés dans le cadre du travail',
      id: '1',
    },
    result: 25461,
    uncertainty: 0.07,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements des employés dans le cadre du travail',
      id: '1',
    },
    result: 18,
    uncertainty: 0.23,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Hors énergie',
      id: '1',
    },
    subpost: {
      name: 'Emissions de gaz hors Kyoto',
      id: '1',
    },
    result: 32570,
    uncertainty: 0.3,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Papiers, cartons',
      id: '1',
    },
    result: 458,
    uncertainty: 0.0,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Nourriture, repas & boissons',
      id: '1',
    },
    result: 1108,
    uncertainty: 0.57,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Nourriture, repas & boissons',
      id: '1',
    },
    result: 18150,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 680,
    uncertainty: 0.04,
    labels: ['Chauffage'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Informatique',
      id: '1',
    },
    result: 6090,
    uncertainty: 0.5,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Intrants',
      id: '1',
    },
    subpost: {
      name: 'Autres intrants',
      id: '1',
    },
    result: 1978,
    uncertainty: 0.36,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: "Achats d'électricité",
      id: '1',
    },
    result: 23495,
    uncertainty: 0.08,
    labels: ['Chauffage'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements domicile-travail',
      id: '1',
    },
    result: 46,
    uncertainty: 0.55,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements domicile-travail',
      id: '1',
    },
    result: 3648,
    uncertainty: 0.44,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Déplacements',
      id: '1',
    },
    subpost: {
      name: 'Déplacements des employés dans le cadre du travail',
      id: '1',
    },
    result: 56,
    uncertainty: 0.46,
    labels: [],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Véhicules, machines et outils',
      id: '1',
    },
    result: 11000,
    uncertainty: 0.66,
    labels: ['Déplacement'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: "Achats d'électricité",
      id: '1',
    },
    result: 206,
    uncertainty: 0.14,
    labels: ['Chauffage', 'Télétravail'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Energie',
      id: '1',
    },
    subpost: {
      name: 'Comptabilisation directe des combustibles',
      id: '1',
    },
    result: 713,
    uncertainty: 0.14,
    labels: ['Chauffage', 'Télétravail'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Bâtiments',
      id: '1',
    },
    result: 5250,
    uncertainty: 0.5,
    labels: ['Télétravail'],
  },
  {
    orga: {
      name: 'Siège social',
      id: 'Pays;Site production',
    },
    poste: {
      name: 'Immobilisations',
      id: '1',
    },
    subpost: {
      name: 'Bâtiments',
      id: '1',
    },
    result: 8500,
    uncertainty: 0.52,
    labels: ['Télétravail'],
  },
]

export const BEGESV5 = [
  {
    orga: { name: 'Siège social', id: '1' },
    category: { name: 'Émisions directes de GES', id: '1.1' },
    poste: {
      name: '1.1 - Emissions directes des sources fixes de combustion',
      id: '1.1',
    },
    emission: 1,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: { name: 'Émisions directes de GES', id: '1.2' },
    poste: {
      name: '1.2 - Emissions directes des sources mobiles de combustion',
      id: '1.2',
    },
    emission: 8,
    uncertainty: 1,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: { name: 'Émisions directes de GES', id: '1.3' },
    poste: {
      name: '1.3 - Emissions directes des procédés hors énergie',
      id: '1.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: { name: 'Émisions directes de GES', id: '1.4' },
    poste: { name: '1.4 - Emissions directes fugitives', id: '1.4' },
    emission: 33,
    uncertainty: 10,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: { name: 'Émisions directes de GES', id: '1.5' },
    poste: {
      name: '1.5 - Emissions issues de la biomasse (sols et forêts)',
      id: '1.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.1',
    },
    poste: {
      name: "2.1 - Emissions indirectes liées à la consommation d'électricité",
      id: '2.1',
    },
    emission: 15,
    uncertainty: 2,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.2',
    },
    poste: {
      name: "2.2 - Emissions indirectes liées à la consommation d'énergie autre que l'électricité",
      id: '2.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.1',
    },
    poste: { name: '3.1 - Transport de marchandise amont', id: '3.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.2',
    },
    poste: { name: '3.2 - Transport de marchandise aval', id: '3.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.3',
    },
    poste: {
      name: '3.3 - Déplacements domicile-travail ',
      id: '3.3',
    },
    emission: 6,
    uncertainty: 2,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.4',
    },
    poste: {
      name: '3.4 - Déplacements des visiteurs et des clients',
      id: '3.4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.5',
    },
    poste: {
      name: '3.5 - Déplacements professionnels',
      id: '3.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.1',
    },
    poste: {
      name: "4.1 - Achats de biens (inclut l'ex-poste 8 et une partie de l'ex-poste 9)",
      id: '4.1',
    },
    emission: 45,
    uncertainty: 9,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.2',
    },
    poste: { name: '4.2 - Immobilisations de biens', id: '4.2' },
    emission: 37,
    uncertainty: 10,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.3',
    },
    poste: { name: '4.3 - Gestion des déchets', id: '4.3' },
    emission: 8,
    uncertainty: 4,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.4',
    },
    poste: { name: '4.4 - Actifs en leasing amont', id: '4.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.5',
    },
    poste: {
      name: "4.5 - Achats de services (inclut une partie de l'ex-poste 9)",
      id: '4.5',
    },
    emission: 156,
    uncertainty: 108,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.1',
    },
    poste: { name: '5.1 - Utilisation des produits vendus', id: '5.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.2',
    },
    poste: { name: '5.2 - Actifs en leasing aval', id: '5.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.3',
    },
    poste: { name: '5.3 - Fin de vie des produits vendus', id: '5.3' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.4',
    },
    poste: { name: '5.4 - Investissements', id: '5.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Siège social', id: '1' },
    category: { name: 'Autres émissions indirectes', id: '6.1' },
    poste: {
      name: '6.1 - Autres émissions indirectes',
      id: '6.1',
    },
    emission: 1,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: { name: 'Émisions directes de GES', id: '1.1' },
    poste: {
      name: '1.1 - Emissions directes des sources fixes de combustion',
      id: '1.1',
    },
    emission: 8,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: { name: 'Émisions directes de GES', id: '1.2' },
    poste: {
      name: '1.2 - Emissions directes des sources mobiles de combustion',
      id: '1.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: { name: 'Émisions directes de GES', id: '1.3' },
    poste: {
      name: '1.3 - Emissions directes des procédés hors énergie',
      id: '1.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: { name: 'Émisions directes de GES', id: '1.4' },
    poste: { name: '1.4 - Emissions directes fugitives', id: '1.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: { name: 'Émisions directes de GES', id: '1.5' },
    poste: {
      name: '1.5 - Emissions issues de la biomasse (sols et forêts)',
      id: '1.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.1',
    },
    poste: {
      name: "2.1 - Emissions indirectes liées à la consommation d'électricité",
      id: '2.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.2',
    },
    poste: {
      name: "2.2 - Emissions indirectes liées à la consommation d'énergie autre que l'électricité",
      id: '2.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.1',
    },
    poste: { name: '3.1 - Transport de marchandise amont', id: '3.1' },
    emission: 346,
    uncertainty: 142,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.2',
    },
    poste: { name: '3.2 - Transport de marchandise aval', id: '3.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.3',
    },
    poste: {
      name: '3.3 - Déplacements domicile-travail ',
      id: '3.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.4',
    },
    poste: {
      name: '3.4 - Déplacements des visiteurs et des clients',
      id: '3.4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.5',
    },
    poste: {
      name: '3.5 - Déplacements professionnels',
      id: '3.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.1',
    },
    poste: {
      name: "4.1 - Achats de biens (inclut l'ex-poste 8 et une partie de l'ex-poste 9)",
      id: '4.1',
    },
    emission: 6670,
    uncertainty: 1134,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.2',
    },
    poste: { name: '4.2 - Immobilisations de biens', id: '4.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.3',
    },
    poste: { name: '4.3 - Gestion des déchets', id: '4.3' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.4',
    },
    poste: { name: '4.4 - Actifs en leasing amont', id: '4.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.5',
    },
    poste: {
      name: "4.5 - Achats de services (inclut une partie de l'ex-poste 9)",
      id: '4.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.1',
    },
    poste: { name: '5.1 - Utilisation des produits vendus', id: '5.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.2',
    },
    poste: { name: '5.2 - Actifs en leasing aval', id: '5.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.3',
    },
    poste: { name: '5.3 - Fin de vie des produits vendus', id: '5.3' },
    emission: 2008,
    uncertainty: 863,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.4',
    },
    poste: { name: '5.4 - Investissements', id: '5.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Varsovie', id: '2' },
    category: { name: 'Autres émissions indirectes', id: '6.1' },
    poste: {
      name: '6.1 - Autres émissions indirectes',
      id: '6.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: { name: 'Émisions directes de GES', id: '1.1' },
    poste: {
      name: '1.1 - Emissions directes des sources fixes de combustion',
      id: '1.1',
    },
    emission: 187,
    uncertainty: 9,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: { name: 'Émisions directes de GES', id: '1.2' },
    poste: {
      name: '1.2 - Emissions directes des sources mobiles de combustion',
      id: '1.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: { name: 'Émisions directes de GES', id: '1.3' },
    poste: {
      name: '1.3 - Emissions directes des procédés hors énergie',
      id: '1.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: { name: 'Émisions directes de GES', id: '1.4' },
    poste: { name: '1.4 - Emissions directes fugitives', id: '1.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: { name: 'Émisions directes de GES', id: '1.5' },
    poste: {
      name: '1.5 - Emissions issues de la biomasse (sols et forêts)',
      id: '1.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.1',
    },
    poste: {
      name: "2.1 - Emissions indirectes liées à la consommation d'électricité",
      id: '2.1',
    },
    emission: 89,
    uncertainty: 29,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.2',
    },
    poste: {
      name: "2.2 - Emissions indirectes liées à la consommation d'énergie autre que l'électricité",
      id: '2.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.1',
    },
    poste: { name: '3.1 - Transport de marchandise amont', id: '3.1' },
    emission: 267,
    uncertainty: 134,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.2',
    },
    poste: { name: '3.2 - Transport de marchandise aval', id: '3.2' },
    emission: 142,
    uncertainty: 97,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.3',
    },
    poste: {
      name: '3.3 - Déplacements domicile-travail ',
      id: '3.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.4',
    },
    poste: {
      name: '3.4 - Déplacements des visiteurs et des clients',
      id: '3.4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.5',
    },
    poste: {
      name: '3.5 - Déplacements professionnels',
      id: '3.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.1',
    },
    poste: {
      name: "4.1 - Achats de biens (inclut l'ex-poste 8 et une partie de l'ex-poste 9)",
      id: '4.1',
    },
    emission: 14667,
    uncertainty: 2347,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.2',
    },
    poste: { name: '4.2 - Immobilisations de biens', id: '4.2' },
    emission: 6,
    uncertainty: 3,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.3',
    },
    poste: { name: '4.3 - Gestion des déchets', id: '4.3' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.4',
    },
    poste: { name: '4.4 - Actifs en leasing amont', id: '4.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.5',
    },
    poste: {
      name: "4.5 - Achats de services (inclut une partie de l'ex-poste 9)",
      id: '4.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.1',
    },
    poste: { name: '5.1 - Utilisation des produits vendus', id: '5.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.2',
    },
    poste: { name: '5.2 - Actifs en leasing aval', id: '5.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.3',
    },
    poste: { name: '5.3 - Fin de vie des produits vendus', id: '5.3' },
    emission: 3580,
    uncertainty: 1432,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.4',
    },
    poste: { name: '5.4 - Investissements', id: '5.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Madrid', id: '3' },
    category: { name: 'Autres émissions indirectes', id: '6.1' },
    poste: {
      name: '6.1 - Autres émissions indirectes',
      id: '6.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: { name: 'Émisions directes de GES', id: '1.1' },
    poste: {
      name: '1.1 - Emissions directes des sources fixes de combustion',
      id: '1.1',
    },
    emission: 155,
    uncertainty: 43,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: { name: 'Émisions directes de GES', id: '1.2' },
    poste: {
      name: '1.2 - Emissions directes des sources mobiles de combustion',
      id: '1.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: { name: 'Émisions directes de GES', id: '1.3' },
    poste: {
      name: '1.3 - Emissions directes des procédés hors énergie',
      id: '1.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: { name: 'Émisions directes de GES', id: '1.4' },
    poste: { name: '1.4 - Emissions directes fugitives', id: '1.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: { name: 'Émisions directes de GES', id: '1.5' },
    poste: {
      name: '1.5 - Emissions issues de la biomasse (sols et forêts)',
      id: '1.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.1',
    },
    poste: {
      name: "2.1 - Emissions indirectes liées à la consommation d'électricité",
      id: '2.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.2',
    },
    poste: {
      name: "2.2 - Emissions indirectes liées à la consommation d'énergie autre que l'électricité",
      id: '2.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.1',
    },
    poste: { name: '3.1 - Transport de marchandise amont', id: '3.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.2',
    },
    poste: { name: '3.2 - Transport de marchandise aval', id: '3.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.3',
    },
    poste: {
      name: '3.3 - Déplacements domicile-travail ',
      id: '3.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.4',
    },
    poste: {
      name: '3.4 - Déplacements des visiteurs et des clients',
      id: '3.4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.5',
    },
    poste: {
      name: '3.5 - Déplacements professionnels',
      id: '3.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.1',
    },
    poste: {
      name: "4.1 - Achats de biens (inclut l'ex-poste 8 et une partie de l'ex-poste 9)",
      id: '4.1',
    },
    emission: 9718,
    uncertainty: 1555,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.2',
    },
    poste: { name: '4.2 - Immobilisations de biens', id: '4.2' },
    emission: 126,
    uncertainty: 95,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.3',
    },
    poste: { name: '4.3 - Gestion des déchets', id: '4.3' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.4',
    },
    poste: { name: '4.4 - Actifs en leasing amont', id: '4.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.5',
    },
    poste: {
      name: "4.5 - Achats de services (inclut une partie de l'ex-poste 9)",
      id: '4.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.1',
    },
    poste: { name: '5.1 - Utilisation des produits vendus', id: '5.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.2',
    },
    poste: { name: '5.2 - Actifs en leasing aval', id: '5.2' },
    emission: 204,
    uncertainty: 110,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.3',
    },
    poste: { name: '5.3 - Fin de vie des produits vendus', id: '5.3' },
    emission: 2343,
    uncertainty: 1148,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.4',
    },
    poste: { name: '5.4 - Investissements', id: '5.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Arcachon', id: '4' },
    category: { name: 'Autres émissions indirectes', id: '6.1' },
    poste: {
      name: '6.1 - Autres émissions indirectes',
      id: '6.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: { name: 'Émisions directes de GES', id: '1.1' },
    poste: {
      name: '1.1 - Emissions directes des sources fixes de combustion',
      id: '1.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: { name: 'Émisions directes de GES', id: '1.2' },
    poste: {
      name: '1.2 - Emissions directes des sources mobiles de combustion',
      id: '1.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: { name: 'Émisions directes de GES', id: '1.3' },
    poste: {
      name: '1.3 - Emissions directes des procédés hors énergie',
      id: '1.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: { name: 'Émisions directes de GES', id: '1.4' },
    poste: { name: '1.4 - Emissions directes fugitives', id: '1.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: { name: 'Émisions directes de GES', id: '1.5' },
    poste: {
      name: '1.5 - Emissions issues de la biomasse (sols et forêts)',
      id: '1.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.1',
    },
    poste: {
      name: "2.1 - Emissions indirectes liées à la consommation d'électricité",
      id: '2.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.2',
    },
    poste: {
      name: "2.2 - Emissions indirectes liées à la consommation d'énergie autre que l'électricité",
      id: '2.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.1',
    },
    poste: { name: '3.1 - Transport de marchandise amont', id: '3.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.2',
    },
    poste: { name: '3.2 - Transport de marchandise aval', id: '3.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.3',
    },
    poste: {
      name: '3.3 - Déplacements domicile-travail ',
      id: '3.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.4',
    },
    poste: {
      name: '3.4 - Déplacements des visiteurs et des clients',
      id: '3.4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.5',
    },
    poste: {
      name: '3.5 - Déplacements professionnels',
      id: '3.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.1',
    },
    poste: {
      name: "4.1 - Achats de biens (inclut l'ex-poste 8 et une partie de l'ex-poste 9)",
      id: '4.1',
    },
    emission: 3895,
    uncertainty: 2103,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.2',
    },
    poste: { name: '4.2 - Immobilisations de biens', id: '4.2' },
    emission: 2428,
    uncertainty: 2015,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.3',
    },
    poste: { name: '4.3 - Gestion des déchets', id: '4.3' },
    emission: 3,
    uncertainty: 1,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.4',
    },
    poste: { name: '4.4 - Actifs en leasing amont', id: '4.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.5',
    },
    poste: {
      name: "4.5 - Achats de services (inclut une partie de l'ex-poste 9)",
      id: '4.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.1',
    },
    poste: { name: '5.1 - Utilisation des produits vendus', id: '5.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.2',
    },
    poste: { name: '5.2 - Actifs en leasing aval', id: '5.2' },
    emission: 25,
    uncertainty: 14,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.3',
    },
    poste: { name: '5.3 - Fin de vie des produits vendus', id: '5.3' },
    emission: 99,
    uncertainty: 34,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.4',
    },
    poste: { name: '5.4 - Investissements', id: '5.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Site production Séville', id: '5' },
    category: { name: 'Autres émissions indirectes', id: '6.1' },
    poste: {
      name: '6.1 - Autres émissions indirectes',
      id: '6.1',
    },
    emission: 4411,
    uncertainty: 618,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: { name: 'Émisions directes de GES', id: '1.1' },
    poste: {
      name: '1.1 - Emissions directes des sources fixes de combustion',
      id: '1.1',
    },
    emission: 2,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: { name: 'Émisions directes de GES', id: '1.2' },
    poste: {
      name: '1.2 - Emissions directes des sources mobiles de combustion',
      id: '1.2',
    },
    emission: 20,
    uncertainty: 3,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: { name: 'Émisions directes de GES', id: '1.3' },
    poste: {
      name: '1.3 - Emissions directes des procédés hors énergie',
      id: '1.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: { name: 'Émisions directes de GES', id: '1.4' },
    poste: { name: '1.4 - Emissions directes fugitives', id: '1.4' },
    emission: 309,
    uncertainty: 93,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: { name: 'Émisions directes de GES', id: '1.5' },
    poste: {
      name: '1.5 - Emissions issues de la biomasse (sols et forêts)',
      id: '1.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.1',
    },
    poste: {
      name: "2.1 - Emissions indirectes liées à la consommation d'électricité",
      id: '2.1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.2',
    },
    poste: {
      name: "2.2 - Emissions indirectes liées à la consommation d'énergie autre que l'électricité",
      id: '2.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.1',
    },
    poste: { name: '3.1 - Transport de marchandise amont', id: '3.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.2',
    },
    poste: { name: '3.2 - Transport de marchandise aval', id: '3.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.3',
    },
    poste: {
      name: '3.3 - Déplacements domicile-travail ',
      id: '3.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.4',
    },
    poste: {
      name: '3.4 - Déplacements des visiteurs et des clients',
      id: '3.4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.5',
    },
    poste: {
      name: '3.5 - Déplacements professionnels',
      id: '3.5',
    },
    emission: 1,
    uncertainty: 1,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.1',
    },
    poste: {
      name: "4.1 - Achats de biens (inclut l'ex-poste 8 et une partie de l'ex-poste 9)",
      id: '4.1',
    },
    emission: 174,
    uncertainty: 70,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.2',
    },
    poste: { name: '4.2 - Immobilisations de biens', id: '4.2' },
    emission: 73,
    uncertainty: 17,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.3',
    },
    poste: { name: '4.3 - Gestion des déchets', id: '4.3' },
    emission: 245,
    uncertainty: 140,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.4',
    },
    poste: { name: '4.4 - Actifs en leasing amont', id: '4.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.5',
    },
    poste: {
      name: "4.5 - Achats de services (inclut une partie de l'ex-poste 9)",
      id: '4.5',
    },
    emission: 280,
    uncertainty: 101,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.1',
    },
    poste: { name: '5.1 - Utilisation des produits vendus', id: '5.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.2',
    },
    poste: { name: '5.2 - Actifs en leasing aval', id: '5.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.3',
    },
    poste: { name: '5.3 - Fin de vie des produits vendus', id: '5.3' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.4',
    },
    poste: { name: '5.4 - Investissements', id: '5.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Espagne', id: '6' },
    category: { name: 'Autres émissions indirectes', id: '6.1' },
    poste: {
      name: '6.1 - Autres émissions indirectes',
      id: '6.1',
    },
    emission: 621,
    uncertainty: 87,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: { name: 'Émisions directes de GES', id: '1.1' },
    poste: {
      name: '1.1 - Emissions directes des sources fixes de combustion',
      id: '1.1',
    },
    emission: 2,
    uncertainty: 1,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: { name: 'Émisions directes de GES', id: '1.2' },
    poste: {
      name: '1.2 - Emissions directes des sources mobiles de combustion',
      id: '1.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: { name: 'Émisions directes de GES', id: '1.3' },
    poste: {
      name: '1.3 - Emissions directes des procédés hors énergie',
      id: '1.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: { name: 'Émisions directes de GES', id: '1.4' },
    poste: { name: '1.4 - Emissions directes fugitives', id: '1.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: { name: 'Émisions directes de GES', id: '1.5' },
    poste: {
      name: '1.5 - Emissions issues de la biomasse (sols et forêts)',
      id: '1.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.1',
    },
    poste: {
      name: "2.1 - Emissions indirectes liées à la consommation d'électricité",
      id: '2.1',
    },
    emission: 29,
    uncertainty: 3,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: "Émissions indirectes associées à l'énergie",
      id: '2.2',
    },
    poste: {
      name: "2.2 - Emissions indirectes liées à la consommation d'énergie autre que l'électricité",
      id: '2.2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.1',
    },
    poste: { name: '3.1 - Transport de marchandise amont', id: '3.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.2',
    },
    poste: { name: '3.2 - Transport de marchandise aval', id: '3.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.3',
    },
    poste: {
      name: '3.3 - Déplacements domicile-travail ',
      id: '3.3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.4',
    },
    poste: {
      name: '3.4 - Déplacements des visiteurs et des clients',
      id: '3.4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées au transport',
      id: '3.5',
    },
    poste: {
      name: '3.5 - Déplacements professionnels',
      id: '3.5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.1',
    },
    poste: {
      name: "4.1 - Achats de biens (inclut l'ex-poste 8 et une partie de l'ex-poste 9)",
      id: '4.1',
    },
    emission: 21,
    uncertainty: 2,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.2',
    },
    poste: { name: '4.2 - Immobilisations de biens', id: '4.2' },
    emission: 66,
    uncertainty: 36,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.3',
    },
    poste: { name: '4.3 - Gestion des déchets', id: '4.3' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.4',
    },
    poste: { name: '4.4 - Actifs en leasing amont', id: '4.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits achetés',
      id: '4.5',
    },
    poste: {
      name: "4.5 - Achats de services (inclut une partie de l'ex-poste 9)",
      id: '4.5',
    },
    emission: 215,
    uncertainty: 77,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.1',
    },
    poste: { name: '5.1 - Utilisation des produits vendus', id: '5.1' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.2',
    },
    poste: { name: '5.2 - Actifs en leasing aval', id: '5.2' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.3',
    },
    poste: { name: '5.3 - Fin de vie des produits vendus', id: '5.3' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: {
      name: 'Émissions indirectes associées aux produits vendus',
      id: '5.4',
    },
    poste: { name: '5.4 - Investissements', id: '5.4' },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: { name: 'Pologne', id: '7' },
    category: { name: 'Autres émissions indirectes', id: '6.1' },
    poste: {
      name: '6.1 - Autres émissions indirectes',
      id: '6.1',
    },
    emission: 0,
    uncertainty: 0,
  },
]

export const GHG = [
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '1-1',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-1',
    },
    poste: {
      name: 'Emissions directes des sources fixes de combustion',
      id: '1-1',
    },
    emission: 1,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '1-2',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-2',
    },
    poste: {
      name: 'Emissions directes des sources mobiles de combustion',
      id: '1-2',
    },
    emission: 8,
    uncertainty: 1,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '1-3',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-3',
    },
    poste: {
      name: 'Emissions directes des proc\u00e9d\u00e9s',
      id: '1-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '1-4',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-4',
    },
    poste: {
      name: 'Emissions directes fugitives',
      id: '1-4',
    },
    emission: 33,
    uncertainty: 10,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '2-1',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-1',
    },
    poste: {
      name: "Emissions indirectes li\u00e9es \u00e0 la consommation d'\u00e9lectricit\u00e9",
      id: '2-1',
    },
    emission: 15,
    uncertainty: 2,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '2-2',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-2',
    },
    poste: {
      name: 'Emissions indirectes li\u00e9es \u00e0 la consommation de vapeur, chaleur ou froid',
      id: '2-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-1',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-1',
    },
    poste: {
      name: 'Produits et services achet\u00e9s',
      id: '3-1',
    },
    emission: 176,
    uncertainty: 107,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-2',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-2',
    },
    poste: {
      name: 'Biens immobilis\u00e9s',
      id: '3-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-3',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-3',
    },
    poste: {
      name: "Emissions li\u00e9es aux combustibles et \u00e0 l'\u00e9nergie (non inclus dans le scope 1 ou le scope 2)",
      id: '3-3',
    },
    emission: 25,
    uncertainty: 2,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-4',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-4',
    },
    poste: {
      name: 'Transport de marchandise amont et distribution',
      id: '3-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-5',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-5',
    },
    poste: {
      name: 'D\u00e9chets g\u00e9n\u00e9r\u00e9s',
      id: '3-5',
    },
    emission: 8,
    uncertainty: 4,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-6',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-6',
    },
    poste: {
      name: 'D\u00e9placements professionnels',
      id: '3-6',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-7',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-7',
    },
    poste: {
      name: 'D\u00e9placements domicile travail',
      id: '3-7',
    },
    emission: 6,
    uncertainty: 2,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-8',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-8',
    },
    poste: {
      name: 'Actifs en leasing amont',
      id: '3-8',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes amont',
      id: 'nan',
    },
    emission: 1,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-9',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-9',
    },
    poste: {
      name: 'Transport de marchandise aval et distribution',
      id: '3-9',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-10',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-10',
    },
    poste: {
      name: 'Transformation des produits vendus',
      id: '3-10',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-11',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-11',
    },
    poste: {
      name: 'Utilisation des produits vendus',
      id: '3-11',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-12',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-12',
    },
    poste: {
      name: 'Fin de vie des produits vendus',
      id: '3-12',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-13',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-13',
    },
    poste: {
      name: 'Actifs en leasing aval',
      id: '3-13',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-14',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-14',
    },
    poste: {
      name: 'Franchises',
      id: '3-14',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: '3-15',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-15',
    },
    poste: {
      name: 'Investissements',
      id: '3-15',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Si\u00e8ge social',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes aval',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '1-1',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-1',
    },
    poste: {
      name: 'Emissions directes des sources fixes de combustion',
      id: '1-1',
    },
    emission: 8,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '1-2',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-2',
    },
    poste: {
      name: 'Emissions directes des sources mobiles de combustion',
      id: '1-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '1-3',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-3',
    },
    poste: {
      name: 'Emissions directes des proc\u00e9d\u00e9s',
      id: '1-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '1-4',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-4',
    },
    poste: {
      name: 'Emissions directes fugitives',
      id: '1-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '2-1',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-1',
    },
    poste: {
      name: "Emissions indirectes li\u00e9es \u00e0 la consommation d'\u00e9lectricit\u00e9",
      id: '2-1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '2-2',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-2',
    },
    poste: {
      name: 'Emissions indirectes li\u00e9es \u00e0 la consommation de vapeur, chaleur ou froid',
      id: '2-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-1',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-1',
    },
    poste: {
      name: 'Produits et services achet\u00e9s',
      id: '3-1',
    },
    emission: 6669,
    uncertainty: 1134,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-2',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-2',
    },
    poste: {
      name: 'Biens immobilis\u00e9s',
      id: '3-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-3',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-3',
    },
    poste: {
      name: "Emissions li\u00e9es aux combustibles et \u00e0 l'\u00e9nergie (non inclus dans le scope 1 ou le scope 2)",
      id: '3-3',
    },
    emission: 1,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-4',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-4',
    },
    poste: {
      name: 'Transport de marchandise amont et distribution',
      id: '3-4',
    },
    emission: 346,
    uncertainty: 142,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-5',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-5',
    },
    poste: {
      name: 'D\u00e9chets g\u00e9n\u00e9r\u00e9s',
      id: '3-5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-6',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-6',
    },
    poste: {
      name: 'D\u00e9placements professionnels',
      id: '3-6',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-7',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-7',
    },
    poste: {
      name: 'D\u00e9placements domicile travail',
      id: '3-7',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-8',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-8',
    },
    poste: {
      name: 'Actifs en leasing amont',
      id: '3-8',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes amont',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-9',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-9',
    },
    poste: {
      name: 'Transport de marchandise aval et distribution',
      id: '3-9',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-10',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-10',
    },
    poste: {
      name: 'Transformation des produits vendus',
      id: '3-10',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-11',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-11',
    },
    poste: {
      name: 'Utilisation des produits vendus',
      id: '3-11',
    },
    emission: 1787,
    uncertainty: 840,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-12',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-12',
    },
    poste: {
      name: 'Fin de vie des produits vendus',
      id: '3-12',
    },
    emission: 2008,
    uncertainty: 863,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-13',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-13',
    },
    poste: {
      name: 'Actifs en leasing aval',
      id: '3-13',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-14',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-14',
    },
    poste: {
      name: 'Franchises',
      id: '3-14',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: '3-15',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-15',
    },
    poste: {
      name: 'Investissements',
      id: '3-15',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Varsovie',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes aval',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '1-1',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-1',
    },
    poste: {
      name: 'Emissions directes des sources fixes de combustion',
      id: '1-1',
    },
    emission: 187,
    uncertainty: 9,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '1-2',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-2',
    },
    poste: {
      name: 'Emissions directes des sources mobiles de combustion',
      id: '1-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '1-3',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-3',
    },
    poste: {
      name: 'Emissions directes des proc\u00e9d\u00e9s',
      id: '1-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '1-4',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-4',
    },
    poste: {
      name: 'Emissions directes fugitives',
      id: '1-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '2-1',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-1',
    },
    poste: {
      name: "Emissions indirectes li\u00e9es \u00e0 la consommation d'\u00e9lectricit\u00e9",
      id: '2-1',
    },
    emission: 89,
    uncertainty: 29,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '2-2',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-2',
    },
    poste: {
      name: 'Emissions indirectes li\u00e9es \u00e0 la consommation de vapeur, chaleur ou froid',
      id: '2-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-1',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-1',
    },
    poste: {
      name: 'Produits et services achet\u00e9s',
      id: '3-1',
    },
    emission: 14607,
    uncertainty: 2337,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-2',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-2',
    },
    poste: {
      name: 'Biens immobilis\u00e9s',
      id: '3-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-3',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-3',
    },
    poste: {
      name: "Emissions li\u00e9es aux combustibles et \u00e0 l'\u00e9nergie (non inclus dans le scope 1 ou le scope 2)",
      id: '3-3',
    },
    emission: 60,
    uncertainty: 7,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-4',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-4',
    },
    poste: {
      name: 'Transport de marchandise amont et distribution',
      id: '3-4',
    },
    emission: 267,
    uncertainty: 134,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-5',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-5',
    },
    poste: {
      name: 'D\u00e9chets g\u00e9n\u00e9r\u00e9s',
      id: '3-5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-6',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-6',
    },
    poste: {
      name: 'D\u00e9placements professionnels',
      id: '3-6',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-7',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-7',
    },
    poste: {
      name: 'D\u00e9placements domicile travail',
      id: '3-7',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-8',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-8',
    },
    poste: {
      name: 'Actifs en leasing amont',
      id: '3-8',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes amont',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-9',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-9',
    },
    poste: {
      name: 'Transport de marchandise aval et distribution',
      id: '3-9',
    },
    emission: 142,
    uncertainty: 97,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-10',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-10',
    },
    poste: {
      name: 'Transformation des produits vendus',
      id: '3-10',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-11',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-11',
    },
    poste: {
      name: 'Utilisation des produits vendus',
      id: '3-11',
    },
    emission: 10,
    uncertainty: 1,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-12',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-12',
    },
    poste: {
      name: 'Fin de vie des produits vendus',
      id: '3-12',
    },
    emission: 3580,
    uncertainty: 1432,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-13',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-13',
    },
    poste: {
      name: 'Actifs en leasing aval',
      id: '3-13',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-14',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-14',
    },
    poste: {
      name: 'Franchises',
      id: '3-14',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: '3-15',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-15',
    },
    poste: {
      name: 'Investissements',
      id: '3-15',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Madrid',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes aval',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '1-1',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-1',
    },
    poste: {
      name: 'Emissions directes des sources fixes de combustion',
      id: '1-1',
    },
    emission: 155,
    uncertainty: 43,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '1-2',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-2',
    },
    poste: {
      name: 'Emissions directes des sources mobiles de combustion',
      id: '1-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '1-3',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-3',
    },
    poste: {
      name: 'Emissions directes des proc\u00e9d\u00e9s',
      id: '1-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '1-4',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-4',
    },
    poste: {
      name: 'Emissions directes fugitives',
      id: '1-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '2-1',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-1',
    },
    poste: {
      name: "Emissions indirectes li\u00e9es \u00e0 la consommation d'\u00e9lectricit\u00e9",
      id: '2-1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '2-2',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-2',
    },
    poste: {
      name: 'Emissions indirectes li\u00e9es \u00e0 la consommation de vapeur, chaleur ou froid',
      id: '2-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-1',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-1',
    },
    poste: {
      name: 'Produits et services achet\u00e9s',
      id: '3-1',
    },
    emission: 9693,
    uncertainty: 1551,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-2',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-2',
    },
    poste: {
      name: 'Biens immobilis\u00e9s',
      id: '3-2',
    },
    emission: 628,
    uncertainty: 477,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-3',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-3',
    },
    poste: {
      name: "Emissions li\u00e9es aux combustibles et \u00e0 l'\u00e9nergie (non inclus dans le scope 1 ou le scope 2)",
      id: '3-3',
    },
    emission: 25,
    uncertainty: 7,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-4',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-4',
    },
    poste: {
      name: 'Transport de marchandise amont et distribution',
      id: '3-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-5',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-5',
    },
    poste: {
      name: 'D\u00e9chets g\u00e9n\u00e9r\u00e9s',
      id: '3-5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-6',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-6',
    },
    poste: {
      name: 'D\u00e9placements professionnels',
      id: '3-6',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-7',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-7',
    },
    poste: {
      name: 'D\u00e9placements domicile travail',
      id: '3-7',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-8',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-8',
    },
    poste: {
      name: 'Actifs en leasing amont',
      id: '3-8',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes amont',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-9',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-9',
    },
    poste: {
      name: 'Transport de marchandise aval et distribution',
      id: '3-9',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-10',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-10',
    },
    poste: {
      name: 'Transformation des produits vendus',
      id: '3-10',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-11',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-11',
    },
    poste: {
      name: 'Utilisation des produits vendus',
      id: '3-11',
    },
    emission: 26,
    uncertainty: 2,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-12',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-12',
    },
    poste: {
      name: 'Fin de vie des produits vendus',
      id: '3-12',
    },
    emission: 2343,
    uncertainty: 1148,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-13',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-13',
    },
    poste: {
      name: 'Actifs en leasing aval',
      id: '3-13',
    },
    emission: 204,
    uncertainty: 110,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-14',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-14',
    },
    poste: {
      name: 'Franchises',
      id: '3-14',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: '3-15',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-15',
    },
    poste: {
      name: 'Investissements',
      id: '3-15',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production Arcachon',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes aval',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '1-1',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-1',
    },
    poste: {
      name: 'Emissions directes des sources fixes de combustion',
      id: '1-1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '1-2',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-2',
    },
    poste: {
      name: 'Emissions directes des sources mobiles de combustion',
      id: '1-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '1-3',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-3',
    },
    poste: {
      name: 'Emissions directes des proc\u00e9d\u00e9s',
      id: '1-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '1-4',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-4',
    },
    poste: {
      name: 'Emissions directes fugitives',
      id: '1-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '2-1',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-1',
    },
    poste: {
      name: "Emissions indirectes li\u00e9es \u00e0 la consommation d'\u00e9lectricit\u00e9",
      id: '2-1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '2-2',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-2',
    },
    poste: {
      name: 'Emissions indirectes li\u00e9es \u00e0 la consommation de vapeur, chaleur ou froid',
      id: '2-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-1',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-1',
    },
    poste: {
      name: 'Produits et services achet\u00e9s',
      id: '3-1',
    },
    emission: 3895,
    uncertainty: 2103,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-2',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-2',
    },
    poste: {
      name: 'Biens immobilis\u00e9s',
      id: '3-2',
    },
    emission: 60000,
    uncertainty: 50400,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-3',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-3',
    },
    poste: {
      name: "Emissions li\u00e9es aux combustibles et \u00e0 l'\u00e9nergie (non inclus dans le scope 1 ou le scope 2)",
      id: '3-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-4',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-4',
    },
    poste: {
      name: 'Transport de marchandise amont et distribution',
      id: '3-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-5',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-5',
    },
    poste: {
      name: 'D\u00e9chets g\u00e9n\u00e9r\u00e9s',
      id: '3-5',
    },
    emission: 3,
    uncertainty: 1,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-6',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-6',
    },
    poste: {
      name: 'D\u00e9placements professionnels',
      id: '3-6',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-7',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-7',
    },
    poste: {
      name: 'D\u00e9placements domicile travail',
      id: '3-7',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-8',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-8',
    },
    poste: {
      name: 'Actifs en leasing amont',
      id: '3-8',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes amont',
      id: 'nan',
    },
    emission: 4411,
    uncertainty: 618,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-9',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-9',
    },
    poste: {
      name: 'Transport de marchandise aval et distribution',
      id: '3-9',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-10',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-10',
    },
    poste: {
      name: 'Transformation des produits vendus',
      id: '3-10',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-11',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-11',
    },
    poste: {
      name: 'Utilisation des produits vendus',
      id: '3-11',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-12',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-12',
    },
    poste: {
      name: 'Fin de vie des produits vendus',
      id: '3-12',
    },
    emission: 99,
    uncertainty: 34,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-13',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-13',
    },
    poste: {
      name: 'Actifs en leasing aval',
      id: '3-13',
    },
    emission: 26,
    uncertainty: 13,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-14',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-14',
    },
    poste: {
      name: 'Franchises',
      id: '3-14',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: '3-15',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-15',
    },
    poste: {
      name: 'Investissements',
      id: '3-15',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Site production S\u00e9ville',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes aval',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '1-1',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-1',
    },
    poste: {
      name: 'Emissions directes des sources fixes de combustion',
      id: '1-1',
    },
    emission: 2,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '1-2',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-2',
    },
    poste: {
      name: 'Emissions directes des sources mobiles de combustion',
      id: '1-2',
    },
    emission: 20,
    uncertainty: 3,
  },
  {
    orga: {
      name: 'Espagne',
      id: '1-3',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-3',
    },
    poste: {
      name: 'Emissions directes des proc\u00e9d\u00e9s',
      id: '1-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '1-4',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-4',
    },
    poste: {
      name: 'Emissions directes fugitives',
      id: '1-4',
    },
    emission: 309,
    uncertainty: 93,
  },
  {
    orga: {
      name: 'Espagne',
      id: '2-1',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-1',
    },
    poste: {
      name: "Emissions indirectes li\u00e9es \u00e0 la consommation d'\u00e9lectricit\u00e9",
      id: '2-1',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '2-2',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-2',
    },
    poste: {
      name: 'Emissions indirectes li\u00e9es \u00e0 la consommation de vapeur, chaleur ou froid',
      id: '2-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-1',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-1',
    },
    poste: {
      name: 'Produits et services achet\u00e9s',
      id: '3-1',
    },
    emission: 426,
    uncertainty: 119,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-2',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-2',
    },
    poste: {
      name: 'Biens immobilis\u00e9s',
      id: '3-2',
    },
    emission: 493,
    uncertainty: 236,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-3',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-3',
    },
    poste: {
      name: "Emissions li\u00e9es aux combustibles et \u00e0 l'\u00e9nergie (non inclus dans le scope 1 ou le scope 2)",
      id: '3-3',
    },
    emission: 28,
    uncertainty: 3,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-4',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-4',
    },
    poste: {
      name: 'Transport de marchandise amont et distribution',
      id: '3-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-5',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-5',
    },
    poste: {
      name: 'D\u00e9chets g\u00e9n\u00e9r\u00e9s',
      id: '3-5',
    },
    emission: 245,
    uncertainty: 140,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-6',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-6',
    },
    poste: {
      name: 'D\u00e9placements professionnels',
      id: '3-6',
    },
    emission: 1,
    uncertainty: 1,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-7',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-7',
    },
    poste: {
      name: 'D\u00e9placements domicile travail',
      id: '3-7',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-8',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-8',
    },
    poste: {
      name: 'Actifs en leasing amont',
      id: '3-8',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes amont',
      id: 'nan',
    },
    emission: 621,
    uncertainty: 87,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-9',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-9',
    },
    poste: {
      name: 'Transport de marchandise aval et distribution',
      id: '3-9',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-10',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-10',
    },
    poste: {
      name: 'Transformation des produits vendus',
      id: '3-10',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-11',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-11',
    },
    poste: {
      name: 'Utilisation des produits vendus',
      id: '3-11',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-12',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-12',
    },
    poste: {
      name: 'Fin de vie des produits vendus',
      id: '3-12',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-13',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-13',
    },
    poste: {
      name: 'Actifs en leasing aval',
      id: '3-13',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-14',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-14',
    },
    poste: {
      name: 'Franchises',
      id: '3-14',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: '3-15',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-15',
    },
    poste: {
      name: 'Investissements',
      id: '3-15',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Espagne',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes aval',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '1-1',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-1',
    },
    poste: {
      name: 'Emissions directes des sources fixes de combustion',
      id: '1-1',
    },
    emission: 2,
    uncertainty: 1,
  },
  {
    orga: {
      name: 'Pologne',
      id: '1-2',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-2',
    },
    poste: {
      name: 'Emissions directes des sources mobiles de combustion',
      id: '1-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '1-3',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-3',
    },
    poste: {
      name: 'Emissions directes des proc\u00e9d\u00e9s',
      id: '1-3',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '1-4',
    },
    Scope: {
      name: 'Scope 1',
      id: '1-4',
    },
    poste: {
      name: 'Emissions directes fugitives',
      id: '1-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '2-1',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-1',
    },
    poste: {
      name: "Emissions indirectes li\u00e9es \u00e0 la consommation d'\u00e9lectricit\u00e9",
      id: '2-1',
    },
    emission: 29,
    uncertainty: 3,
  },
  {
    orga: {
      name: 'Pologne',
      id: '2-2',
    },
    Scope: {
      name: 'Scope 2',
      id: '2-2',
    },
    poste: {
      name: 'Emissions indirectes li\u00e9es \u00e0 la consommation de vapeur, chaleur ou froid',
      id: '2-2',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-1',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-1',
    },
    poste: {
      name: 'Produits et services achet\u00e9s',
      id: '3-1',
    },
    emission: 220,
    uncertainty: 77,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-2',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-2',
    },
    poste: {
      name: 'Biens immobilis\u00e9s',
      id: '3-2',
    },
    emission: 39,
    uncertainty: 13,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-3',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-3',
    },
    poste: {
      name: "Emissions li\u00e9es aux combustibles et \u00e0 l'\u00e9nergie (non inclus dans le scope 1 ou le scope 2)",
      id: '3-3',
    },
    emission: 15,
    uncertainty: 1,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-4',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-4',
    },
    poste: {
      name: 'Transport de marchandise amont et distribution',
      id: '3-4',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-5',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-5',
    },
    poste: {
      name: 'D\u00e9chets g\u00e9n\u00e9r\u00e9s',
      id: '3-5',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-6',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-6',
    },
    poste: {
      name: 'D\u00e9placements professionnels',
      id: '3-6',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-7',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-7',
    },
    poste: {
      name: 'D\u00e9placements domicile travail',
      id: '3-7',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-8',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: '3-8',
    },
    poste: {
      name: 'Actifs en leasing amont',
      id: '3-8',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Amont',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes amont',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-9',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-9',
    },
    poste: {
      name: 'Transport de marchandise aval et distribution',
      id: '3-9',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-10',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-10',
    },
    poste: {
      name: 'Transformation des produits vendus',
      id: '3-10',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-11',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-11',
    },
    poste: {
      name: 'Utilisation des produits vendus',
      id: '3-11',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-12',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-12',
    },
    poste: {
      name: 'Fin de vie des produits vendus',
      id: '3-12',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-13',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-13',
    },
    poste: {
      name: 'Actifs en leasing aval',
      id: '3-13',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-14',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-14',
    },
    poste: {
      name: 'Franchises',
      id: '3-14',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: '3-15',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: '3-15',
    },
    poste: {
      name: 'Investissements',
      id: '3-15',
    },
    emission: 0,
    uncertainty: 0,
  },
  {
    orga: {
      name: 'Pologne',
      id: 'nan',
    },
    Scope: {
      name: 'Scope 3 Aval',
      id: 'nan',
    },
    poste: {
      name: 'Autres \u00e9missions indirectes aval',
      id: 'nan',
    },
    emission: 0,
    uncertainty: 0,
  },
]
