import { BEGESV5 } from '../../utils/constants/data'

export const useBegesPage = ({ filtredBegesData, filtredOraganisations, filtredCategories }) => {
  const allBegesCategories = [...new Set(BEGESV5.map(item => item.category.name))] ?? []
  const allBegesCategoriesInitObject = allBegesCategories?.reduce((prev, current) => ({ ...prev, [current]: 0 }), {})
  const barsData = filtredBegesData.reduce((prev, current) => {
    const categoryName = current.category.name
    const emission = current.emission
    return filtredCategories.includes(categoryName)
      ? { ...prev, [categoryName]: (prev[categoryName] ?? 0) + emission }
      : prev
  }, allBegesCategoriesInitObject)

  const sortedBarData = Object.fromEntries(Object.entries(barsData).sort(([, a], [, b]) => b - a))

  const organisationData = filtredBegesData.reduce((prev, current) => {
    const organisationName = current.orga.name
    const emission = current.emission
    return filtredOraganisations.includes(organisationName)
      ? {
          ...prev,
          [organisationName]: { label: organisationName, emission: (prev[organisationName]?.emission ?? 0) + emission },
        }
      : prev
  }, [])

  const sortedOrganisationData = Object.values(organisationData)?.sort((a, b) => b.emission - a.emission)

  // new  uncertainty calculation

  const allPosts = [...new Set(filtredBegesData.map(item => item.poste.name))]
  const postsUncertainty = allPosts.reduce((prev, current) => {
    let coefficient = 0
    let total = 0
    filtredBegesData.forEach(item => {
      if (item.poste.name === current) {
        total += item.emission
        coefficient += Math.pow(item.uncertainty, 2)
      }
    })
    return { ...prev, [current]: { total, uncertainty: total !== 0 ? (Math.sqrt(coefficient) / total) * 100 : 0 } }
  }, {})

  const allCategories = [...new Set(filtredBegesData.map(item => item.category.name))]

  const categoriesPosts = allCategories.reduce((prev, current) => {
    let posts = []
    let coefficient = 0
    let total = 0
    filtredBegesData.forEach(item => {
      const posteId = item?.poste?.id
      const postName = item.poste.name?.replace(posteId + ' -', '')
      if (
        item.category.name === current &&
        !posts.map(post => post.name?.replace(posteId + ' -', '')).includes(postName)
      ) {
        const idPostName = posteId + ' -' + postName
        posts.push({ id: posteId, name: postName, ...postsUncertainty[idPostName] })
        total += postsUncertainty[idPostName]?.total
        coefficient += Math.pow(
          (postsUncertainty[idPostName].uncertainty / 100) * postsUncertainty[idPostName].total,
          2,
        )
      }
    })
    return [
      ...prev,
      { name: current, posts, total, uncertainty: total !== 0 ? (Math.sqrt(coefficient) / total) * 100 : 0 },
    ]
  }, [])

  return {
    barsData: sortedBarData,
    organisationData: sortedOrganisationData,
    categoriesPosts,
  }
}
