import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'd3'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { Grid, Typography } from '@mui/material'
import { TooltipWrapper } from '@nivo/tooltip'

const formatter = format('.3~s')

const CustomTooltip = ({ data }) => {
  return (
    <TooltipWrapper anchor="left" position={[0, 0]}>
      <Grid
        container
        alignContent={'center'}
        sx={{ borderRadius: '5px', opacity: 0.9, padding: '7px', backgroundColor: '#FFF', width: '250px' }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            color: '#253243',
            fontSize: '12px',
            fontWeight: 'medium',
          }}
        >
          {data?.node?.data?.name} :
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            color: '#253243',
            fontSize: '12px',
            fontWeight: 'medium',
          }}
        >
          {`${
            data?.node?.data?.emission === 0
              ? 0
              : data?.node?.data?.emission >= 1
              ? formatter(data?.node?.data?.emission)
              : data?.node?.data?.emission?.toFixed(3)
          }
           tCO₂e`}
        </Typography>
      </Grid>
    </TooltipWrapper>
  )
}
CustomTooltip.propTypes = {
  data: PropTypes.object,
}

const GhgTreeMap = ({ data }) => {
  const colors = Array.apply(null, Array(20)).map((_, index) => `hsl(246, 74%, ${63 + index * 6.5}%)`)
  return (
    <ResponsiveTreeMap
      data={data}
      identity="id"
      value="emission"
      leavesOnly={true}
      valueFormat=".3~s"
      tile="binary"
      label={'id'}
      margin={{ top: 10, right: 2, bottom: 10, left: 1 }}
      labelSkipSize={15}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.2]],
      }}
      orientLabel={false}
      parentLabelPosition="bottom"
      parentLabelTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      colors={colors}
      borderWidth={2}
      borderColor={{
        from: 'color',
        modifiers: [['brighter', '3']],
      }}
      isInteractive={true}
      nodeOpacity={0.7}
      tooltip={props => <CustomTooltip data={props} />}
    />
  )
}

GhgTreeMap.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GhgTreeMap
