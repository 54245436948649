import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { format } from 'd3'
const formatter = format('.3~s')

const Row = ({ item, indexRow }) => {
  const posts = Object.values(item?.posts)
  return (
    <TableBody>
      {posts.map((postItem, index) => (
        <TableRow key={`${item?.name}.${index}`}>
          {index === 0 && (
            <TableCell
              sx={{
                borderBottom: '0px',
                borderRight: '1px solid #E6EAEE',
                padding: '5px 16px',
                backgroundColor: '#F8F8F8',
              }}
              align="left"
              rowSpan={posts?.length}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                {item?.name}
              </Typography>
            </TableCell>
          )}

          <TableCell
            sx={{
              padding: '5px 16px',
            }}
            align="center"
          >
            <Typography
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '14px',
              }}
            >
              {postItem?.id !== 'nan' ? postItem?.id : ''}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              padding: '5px 16px',
            }}
            align="left"
          >
            <Typography
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '14px',
                fontWeight: 'medium',
              }}
            >
              {postItem?.name}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              padding: '5px 16px',
            }}
            align="left"
          >
            <Typography
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '14px',
              }}
              align="left"
            >
              {Number(postItem?.uncertainty?.toFixed(0))}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              padding: '5px 16px',
            }}
          >
            <Typography
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '14px',
              }}
            >
              {postItem?.total === 0
                ? 0
                : postItem?.total >= 1
                ? formatter(postItem?.total)
                : postItem?.total?.toFixed(3)}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
      <TableRow key={indexRow}>
        <TableCell
          sx={{
            padding: '5px 16px',
            backgroundColor: '#F8F8F8',
            borderRight: '1px solid #E6EAEE',
          }}
        ></TableCell>
        <TableCell sx={{ padding: '5px 16px', backgroundColor: '#F8F8F8' }}></TableCell>
        <TableCell
          sx={{
            padding: '5px 16px',
            backgroundColor: '#F8F8F8',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              color: '#253243',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Total {item?.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ padding: '5px 30px', backgroundColor: '#F8F8F8' }}>
          <Typography>{Number(item?.uncertainty?.toFixed(0))}</Typography>
        </TableCell>
        <TableCell sx={{ padding: '5px 30px', backgroundColor: '#F8F8F8' }}>
          <Typography>
            {item?.total === 0 ? 0 : item?.total >= 1 ? formatter(item?.total) : item?.total?.toFixed(3)}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
Row.propTypes = {
  item: PropTypes.object,
  indexRow: PropTypes.number,
}
const BegesPostsTable = ({ data }) => {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 600,
              }}
              align="left"
            >
              Catégories d’émissions
            </TableCell>
            <TableCell
              align="center"
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              #
            </TableCell>
            <TableCell
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              Postes d’émissions
            </TableCell>
            <TableCell
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              Incertitude (%)
            </TableCell>
            <TableCell
              sx={{
                padding: '5px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              Total (tCO₂e)
            </TableCell>
          </TableRow>
        </TableHead>

        {data?.map((item, indexRow) => (
          <Row key={indexRow} item={item} indexRow={indexRow} />
        ))}
      </Table>
    </TableContainer>
  )
}

BegesPostsTable.propTypes = {
  data: PropTypes.array,
}

export default BegesPostsTable
