import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { format } from 'd3'

const RecapCo2OrgaChart = ({ organisations, values }) => {
  const formatter = format('.3~s')

  const chartRef = useRef(null)

  useEffect(() => {
    const chartContainer = d3.select(chartRef.current)

    const updateChart = () => {
      chartContainer.selectAll('*').remove()

      const containerWidth =
        chartRef.current.offsetWidth > 668 ? chartContainer.node().getBoundingClientRect().width : 700
      const margin = { top: 30, right: 20, bottom: 100, left: 40 }
      const width = containerWidth - margin.left - margin.right
      const height = chartRef.current.offsetWidth > 668 ? 400 : 350

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(values)])
        .range([height, 0])

      const xScale = d3.scaleBand().domain(organisations).range([0, width]).padding(0.1)

      const svg = chartContainer
        .append('svg')
        .attr('width', containerWidth)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      svg
        .selectAll('.verticalLine')
        .data(xScale.domain())
        .enter()
        .append('line')
        .attr('class', 'verticalLine')
        .attr('x1', d => xScale(d) + xScale.bandwidth() / 2)
        .attr('y1', 0)
        .attr('x2', d => xScale(d) + xScale.bandwidth() / 2)
        .attr('y2', height + 20)
        .style('stroke', '#ddd')
        .style('stroke-width', 1)
        .style('stroke-dasharray', '4')

      svg
        .selectAll('.horizontalLine')
        .data(yScale.ticks())
        .enter()
        .append('line')
        .attr('class', 'horizontalLine')
        .attr('x1', 20)
        .attr('y1', d => yScale(d))
        .attr('x2', width - 30)
        .attr('y2', d => yScale(d))
        .style('stroke', '#ddd')
        .style('stroke-width', 1)
        .style('stroke-dasharray', '4')

      const bars = svg.selectAll('.bar').data(values, (d, i) => `bar-${i}`)

      bars
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('id', (d, i) => `bar-${i}`)
        .attr('x', (d, i) => xScale(organisations[i]) + xScale.bandwidth() / 2 - 37.5)
        .attr('y', height)
        .attr('width', '75px')
        .attr('fill', '#A096EF')
        .merge(bars)
        .transition()
        .duration(1000)
        .attr('y', d => yScale(d))
        .attr('height', d => height - yScale(d))

      svg
        .selectAll('.valueLabel')
        .data(values)
        .enter()
        .append('text')
        .attr('class', 'valueLabel')
        .attr('x', (d, i) => xScale(organisations[i]) + xScale.bandwidth() / 2)
        .attr('y', d => yScale(d) - 6)
        .attr('text-anchor', 'middle')
        .text(d => (d === 0 ? 0 : d >= 1 ? formatter(d) : d.toFixed(3) + ' tCO₂e'))
        .style('fill', '#253243')
        .style('font-weight', '500')
        .style('font-family', 'Poppins')
        .style('font-size', '14px')

      svg
        .selectAll('.orgName')
        .data(values)
        .enter()
        .append('text')
        .attr('class', 'orgName')
        .attr('x', (d, i) => xScale(organisations[i]) + xScale.bandwidth() / 2)
        .attr('y', height + 40)
        .attr('text-anchor', 'middle')
        .style('fill', '#253243')
        .style('font-weight', '500')
        .style('font-family', 'Poppins')
        .style('font-size', '13px')
        .each(function (d, i) {
          const textNode = d3.select(this)
          const words = organisations[i].split(/\s+/).reverse() // Sépare le texte en mots
          let word
          let line = []
          const lineHeight = 1.5
          const y = parseFloat(textNode.attr('y'))
          const x = parseFloat(textNode.attr('x'))
          const dy = parseFloat(textNode.attr('dy')) || 0

          let tspan = textNode
            .text(null)
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', dy + 'em')

          while ((word = words.pop())) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > xScale.bandwidth()) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              tspan = textNode
                .append('tspan')
                .attr('x', x)
                .attr('dy', lineHeight + dy + 'em')
                .text(word)
            }
          }
        })

      svg
        .append('line')
        .attr('class', 'x-line')
        .attr('x1', 0)
        .attr('y1', height)
        .attr('x2', width)
        .attr('y2', height)
        .style('stroke', '#D6D6D6')
        .style('stroke-width', 1)
    }

    updateChart()

    window.addEventListener('resize', updateChart)

    return () => {
      window.removeEventListener('resize', updateChart)
    }
  }, [values, organisations])

  return <div ref={chartRef} style={{ width: '100%' }}></div>
}

RecapCo2OrgaChart.propTypes = {
  organisations: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
}

export default RecapCo2OrgaChart
