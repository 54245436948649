import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { motion } from 'framer-motion'
import { format } from 'd3'

const formatter = format('.3~s')

const CustomTooltip = ({ payload }) => {
  const { name, value, color } = payload?.[0]?.payload?.payload ?? { name: '', value: '', color: '' }

  return (
    <Grid
      container
      alignItems={'center'}
      sx={{ borderRadius: '5px', opacity: 0.9, padding: '7px', backgroundColor: '#FFF' }}
    >
      <Grid
        item
        sx={{
          width: '20px',
          height: '20px',
          marginRight: '7px',
          marginBottom: '3px',
          backgroundColor: color,
          borderRadius: '4px',
        }}
      />
      <Grid item>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            color: '#253243',
            fontSize: '12px',
            fontWeight: 'medium',
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            color: '#6B5CE7',
            fontSize: '12px',
          }}
        >
          {value === 0 ? 0 : value >= 1 ? formatter(value) : Number(value)?.toFixed(3)} tCO₂e
        </Typography>
      </Grid>
    </Grid>
  )
}
CustomTooltip.propTypes = {
  payload: PropTypes.array,
}

const LegendItem = ({ color, name, value, delay }) => {
  return (
    <Grid item>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: delay, duration: 0.5 }}
      >
        <Grid container flexDirection={'column'} sx={{ marginTop: '5px' }}>
          <Grid
            item
            sx={{
              width: '20px',
              height: '20px',
              marginRight: '5px',
              marginBottom: '3px',
              backgroundColor: color,
              borderRadius: '4px',
            }}
          />
          <Grid item>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 'medium',
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: '#6B5CE7',
                fontSize: '12px',
              }}
            >
              {value === 0 ? 0 : value >= 1 ? formatter(value) : Number(value)?.toFixed(3)} tCO₂e
            </Typography>
          </Grid>
        </Grid>
      </motion.div>
    </Grid>
  )
}
LegendItem.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  delay: PropTypes.number,
}
const BegesOrgaPieChart = ({ data }) => {
  const formattedData = data?.map((orga, index) => ({
    name: orga.label,
    value: orga.emission,
    color: `hsl(246, 74%, ${63 + index * 5}%)`,
  }))
  const legendRef = useRef(null)

  const [legendWidth, setLegendWidth] = useState(0)
  const [diplayLegend, setDiplayLegend] = useState(false)
  useEffect(() => {
    setTimeout(() => setDiplayLegend(true), 2200)
  }, [])

  useLayoutEffect(() => {
    setLegendWidth(legendRef.current.offsetWidth)
  }, [])

  return (
    <Grid container>
      <Grid
        ref={legendRef}
        item
        xs={4}
        sx={{ paddingLeft: '15px', marginTop: '10px', maxHeight: { xs: '450px', lg: '480px', xl: '490px' } }}
        overflow={diplayLegend && legendWidth < 155 ? 'auto' : 'hidden'}
      >
        {formattedData.map((entry, index) => (
          <LegendItem key={index} name={entry.name} value={entry.value} delay={index * 0.2 + 0.7} color={entry.color} />
        ))}
      </Grid>
      <Grid container item xs={8} height={'500px'}>
        <ResponsiveContainer width="100%" aspect={1} style={{ alignItems: 'center', display: 'flex' }}>
          <PieChart width={150} height={150}>
            <Pie
              data={formattedData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={'100%'}
              innerRadius={'47%'}
              fill="#8884d8"
            >
              {formattedData.map((entry, index) => (
                <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  )
}
BegesOrgaPieChart.propTypes = {
  data: PropTypes.array,
}
export default BegesOrgaPieChart
