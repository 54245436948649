import React from 'react'
import App from './components/App/App'
import CssBaseline from '@mui/material/CssBaseline'
import { createRoot } from 'react-dom/client'

const root = createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
)
