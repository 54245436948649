import React from 'react'

import { ReactComponent as IntrantIcon } from '../../utils/icons/IntrantIcon.svg'
import { ReactComponent as FretIcon } from '../../utils/icons/FretIcon.svg'
import { ReactComponent as DechetIcon } from '../../utils/icons/DechetIcon.svg'
import { ReactComponent as DeplacementIcon } from '../../utils/icons/DeplacementIcon.svg'
import { ReactComponent as EmballageIcon } from '../../utils/icons/EmballageIcon.svg'
import { ReactComponent as EnergyIcon } from '../../utils/icons/EnergyIcon.svg'
import { ReactComponent as FinanceIcon } from '../../utils/icons/FinanceIcon.svg'
import { ReactComponent as FindeVieIcon } from '../../utils/icons/FindeVieIcon.svg'
import { ReactComponent as ImmoIcon } from '../../utils/icons/ImmoIcon.svg'
import { ReactComponent as UtilisationIcon } from '../../utils/icons/UtilisationIcon.svg'
import { ReactComponent as HorsEnergyIcon } from '../../utils/icons/HorsEnergyIcon.svg'
import { CO2RECAP } from '../../utils/constants/data'

export const useCo2RacapPage = ({ filtredCO2RECAPData, filtredPosts }) => {
  const allCo2RecapOrganisations = [...new Set(CO2RECAP.map(item => item.orga.name))] ?? []
  const co2RecapOrganisationsInitObject = allCo2RecapOrganisations?.reduce(
    (prev, current) => ({ ...prev, [current]: 0 }),
    {},
  )
  const barsData = filtredCO2RECAPData.reduce((prev, current) => {
    const orgaName = current.orga.name
    const result = current.result / 1000
    return { ...prev, [orgaName]: (prev[orgaName] ?? 0) + result }
  }, co2RecapOrganisationsInitObject)

  const sortedBarData = Object.fromEntries(Object.entries(barsData).sort(([, a], [, b]) => b - a))

  const getIconByPost = postName => {
    switch (postName) {
      case 'Intrants':
        return <IntrantIcon />
      case 'Fin de vie':
        return <FindeVieIcon />
      case 'Energie':
        return <EnergyIcon />
      case 'Immobilisations':
        return <ImmoIcon />
      case 'Futurs emballages':
        return <EmballageIcon />
      case 'Utilisation':
        return <UtilisationIcon />
      case 'Fret':
        return <FretIcon />
      case 'Hors énergie':
        return <HorsEnergyIcon />
      case 'Déchets directs':
        return <DechetIcon />
      case 'Déplacements':
        return <DeplacementIcon />
      case 'Finance':
        return <FinanceIcon />
      default:
        return <IntrantIcon />
    }
  }
  const totalResult = filtredCO2RECAPData.reduce((prev, current) => prev + current.result, 0)

  const allCo2RecapPosts = [...new Set(CO2RECAP.map(item => item.poste.name))] ?? []

  const postPercentageData = filtredPosts.map(postName => {
    let countSubpost = {}

    const postTotalResult = filtredCO2RECAPData.reduce((prev, current) => {
      const subPostName = current?.subpost?.name
      countSubpost = { ...countSubpost, [subPostName]: (countSubpost[subPostName] ?? 0) + 1 }
      return current.poste.name === postName ? prev + current.result : prev
    }, 0)

    const subPosts = filtredCO2RECAPData.reduce((prev, current) => {
      const subPostName = current?.subpost?.name
      const subPostResult = current?.result
      const subPostUncertainty = current?.uncertainty
      return current.poste.name === postName
        ? {
            ...prev,
            [subPostName]: {
              ...prev[subPostName],
              result: (prev[subPostName]?.result ?? 0) + subPostResult,
              uncertainty: (prev[subPostName]?.uncertainty ?? 0) + subPostUncertainty / countSubpost[subPostName],
            },
          }
        : prev
    }, {})

    return {
      name: postName,
      percentage: (postTotalResult / totalResult) * 100,
      icon: getIconByPost(postName),
      total: postTotalResult,
      uncertainty:
        (Object.values(subPosts ?? {})?.reduce((prev, current) => prev + current.uncertainty, 0) /
          Object.keys(subPosts ?? {})?.length) *
        100,
      subPosts: subPosts,
    }
  })
  const allPostPercentageData = allCo2RecapPosts?.map(post => ({
    name: post,
    percentage: 0,
    icon: getIconByPost(post),
    total: 0,
    uncertainty: 0,
    subPosts: [],
  }))
  const mergedPostPercentageData = allPostPercentageData.map(post => {
    const postReady = postPercentageData?.find(item => item?.name === post?.name)
    return postReady ? postReady : post
  })

  const sortedPostData = mergedPostPercentageData.sort((a, b) => b.percentage - a.percentage)

  // new  uncertainty calculation

  const allSubPosts = [...new Set(filtredCO2RECAPData.map(item => item.poste.name + ' -' + item.subpost.name))]

  const subPostsUncertainty = allSubPosts.reduce((prev, current) => {
    let coefficient = 0
    let total = 0
    filtredCO2RECAPData.forEach(item => {
      if (item.poste.name + ' -' + item.subpost.name === current) {
        total += item.result
        coefficient += Math.pow(item.uncertainty * item.result, 2)
      }
    })
    return { ...prev, [current]: { total, uncertainty: total !== 0 ? Math.sqrt(coefficient) / total : 0 } }
  }, {})

  const allPosts = [...new Set(filtredCO2RECAPData.map(item => item.poste.name))]

  const postsSubposts = allPosts.reduce((prev, current) => {
    let subPosts = []
    let coefficient = 0
    let total = 0
    filtredCO2RECAPData.forEach(item => {
      const subpostName = item.poste.name + ' -' + item.subpost.name
      if (item.poste.name === current && !subPosts.map(subpost => subpost.name).includes(subpostName)) {
        subPosts.push({ name: subpostName, ...subPostsUncertainty[subpostName] })
        total += subPostsUncertainty[subpostName]?.total
        coefficient += Math.pow(
          subPostsUncertainty[subpostName]?.uncertainty * subPostsUncertainty[subpostName].total,
          2,
        )
      }
    })
    return [
      ...prev,
      {
        icon: getIconByPost(current),
        name: current,
        subPosts: subPosts
          ?.map(item => ({ ...item, name: item?.name?.replace(current + ' -', '') }))
          ?.sort((a, b) => b.total - a.total),
        total,
        uncertainty: total !== 0 ? (Math.sqrt(coefficient) / total) * 100 : 0,
      },
    ]
  }, [])
  const sortedpostsSubposts = postsSubposts.sort((a, b) => b.total - a.total)

  return { barsData: sortedBarData, postPercentageData: sortedPostData, sortedpostsSubposts }
}
