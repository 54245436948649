const AppStyles = {
  globalContainer: {
    height: '100vh',
  },
  toolbarStyle: {
    height: '50px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6EAEE',
    boxShadow: '0px 3px 6px #4141410F',
  },
  appBarStyle: {
    backgroundColor: '#FFFFFF',
    minHeight: '60px',
    boxShadow: '0px 0px 0px 0px',
    border: '1px solid #E6EAEE',
  },
}

export { AppStyles }
