import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import * as ReactDOMServer from 'react-dom/server'

const RecapCo2PostsChart = ({ data }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    const margin = { top: 10, right: 62, bottom: 30, left: 170 }

    const updateChart = () => {
      d3.select(chartRef.current).selectAll('*').remove()

      const containerWidth = chartRef.current.offsetWidth
      const width = containerWidth - margin.left - margin.right
      const height = data.length * 44

      const xScale = d3.scaleLinear().domain([0, 100]).range([0, width])
      const yScale = d3
        .scaleBand()
        .domain(data.map(d => d.name))
        .range([0, height])
        .padding(0.4)

      const svg = d3.select(chartRef.current).append('svg').attr('width', containerWidth).attr('height', height)

      svg
        .selectAll('.background-bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'background-bar')
        .attr('x', margin.left)
        .attr('y', d => margin.top + yScale(d.name))
        .attr('width', xScale(100))
        .attr('height', yScale.bandwidth())
        .attr('fill', '#6B5CE7')
        .attr('opacity', 0.18)
        .attr('rx', 6)

      svg
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', margin.left)
        .attr('y', d => margin.top + yScale(d.name))
        .attr('width', 0)
        .attr('height', yScale.bandwidth())
        .attr('fill', '#6B5CE7')
        .attr('opacity', 0.53)
        .attr('rx', 6)
        .transition()
        .duration(1000)
        .attr('width', d => xScale(d.percentage))

      const icons = svg
        .selectAll('.icon')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'icon')
        .attr(
          'transform',
          d => `translate(${margin.left - 100},${margin.top + yScale(d.name) + yScale.bandwidth() / 2})`,
        )

      icons
        .append('text')
        .text(d => d.name)
        .attr('x', d => -d.name?.length * 4)
        .attr('y', 6)
        .style('fill', '#253243')
        .style('font-weight', '500')
        .style('font-family', 'Poppins')
        .style('font-size', '13px')

      icons
        .append('foreignObject')
        .attr('width', 22)
        .attr('height', 22)
        .attr('x', 60)
        .attr('y', -10)
        .html(d => ReactDOMServer.renderToStaticMarkup(d.icon))

      svg
        .selectAll('.background-percentage-text')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'background-percentage-text')
        .text(d => `${d.percentage.toFixed(1)} %`)
        .style('fill', '#253243')
        .style('font-weight', '500')
        .style('font-family', 'Poppins')
        .style('font-size', '13px')
        .attr('x', margin.left + xScale(100) + 10)
        .attr('y', d => margin.top + yScale(d.name) + yScale.bandwidth() / 2)
        .style('dominant-baseline', 'middle')
        .style('fill', '#555')

      svg
        .selectAll('.grid-line')
        .data(xScale.ticks(chartRef.current.offsetWidth > 528 ? 10 : 5))
        .enter()
        .append('line')
        .attr('class', 'grid-line')
        .attr('x1', d => margin.left + xScale(d))
        .attr('y1', margin.top)
        .attr('x2', d => margin.left + xScale(d))
        .attr('y2', height - margin.bottom)
        .style('stroke', '#ddd')
        .style('stroke-width', 1)
        .style('stroke-dasharray', '4')
    }

    updateChart()

    window.addEventListener('resize', updateChart)

    return () => {
      window.removeEventListener('resize', updateChart)
    }
  }, [data])

  return <div ref={chartRef} style={{ width: '100%' }}></div>
}

RecapCo2PostsChart.propTypes = {
  data: PropTypes.array.isRequired,
}

export default RecapCo2PostsChart
