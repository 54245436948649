import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Grid, IconButton, Menu, MenuItem, Popover, Radio, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSpring, animated } from 'react-spring'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDebouncedCallback } from 'use-debounce'

import { ReactComponent as EmissionsLightIcon } from '../../utils/icons/EmissionsLightIcon.svg'
import { ReactComponent as EmissionsIcon } from '../../utils/icons/EmissionsIcon.svg'
import { ReactComponent as FiltersIcon } from '../../utils/icons/FiltersIcon.svg'
import { useStatsAndFilters } from './StatsAndFiltersHook'
import { ReactComponent as OrganisationIcon } from '../../utils/icons/OrganisationIcon.svg'
import { ReactComponent as LabelIcon } from '../../utils/icons/LabelIcon.svg'
import { ReactComponent as PostIcon } from '../../utils/icons/PostIcon.svg'

const IconTextField = ({ iconStart, iconEnd, InputProps, ...rest }) => {
  return (
    <TextField
      {...rest}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null,
        endAdornment: iconEnd ? <InputAdornment position="end">{iconEnd}</InputAdornment> : null,
      }}
    />
  )
}

IconTextField.propTypes = {
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  InputProps: PropTypes.object,
}

const FilterItemsListing = ({ menuFilters, initFilters, selectedOperator, setSelectedOperator, handleSelectItem }) => {
  const [searchQuery, setSearchQuery] = useState('')

  const selectedFilterTab = useMemo(() => {
    if (menuFilters.openLabelFilter) return 'labels'
    if (menuFilters.openOrgaFilter) return 'organisations'
    if (menuFilters.openPostFilter) return 'posts'
    if (menuFilters.openScopeFilter) return 'scopes'
    if (menuFilters.openCategoryFilter) return 'categories'
  }, [menuFilters])

  const debounced = useDebouncedCallback(value => {
    value?.length > 1 ? setSearchQuery(value) : setSearchQuery('')
  }, 500)

  const filtredElements = useMemo(() => {
    if (searchQuery === '') return initFilters?.[selectedFilterTab]?.all
    else {
      return initFilters?.[selectedFilterTab]?.all?.filter(item =>
        item?.toLocaleLowerCase()?.includes(searchQuery?.toLocaleLowerCase()),
      )
    }
  }, [searchQuery, initFilters?.[selectedFilterTab]?.all])

  return (
    <Grid
      container
      alignContent={'start'}
      sx={{ height: selectedFilterTab === 'labels' ? '340px' : '250px', width: '285px', padding: '10px' }}
    >
      {selectedFilterTab === 'labels' && (
        <Grid item container sx={{ marginBottom: '5px' }}>
          <Grid item container alignItems={'center'}>
            <Grid item xs={7}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#444444',
                  fontSize: '12px',
                }}
              >
                Par défaut
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#444444',
                  fontSize: '11px',
                  fontWeight: 'bold',
                }}
              >
                OU
              </Typography>
            </Grid>
            <Grid item xs={2.5} container justifyContent={'flex-end'}>
              <Radio
                sx={{ padding: '4px' }}
                size="small"
                onChange={() => setSelectedOperator('or')}
                value={'or'}
                checked={selectedOperator === 'or'}
              />
            </Grid>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item xs={7}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#444444',
                  fontSize: '12px',
                }}
              >
                Combine la sélection
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#444444',
                  fontSize: '11px',
                  fontWeight: 'bold',
                }}
              >
                ET
              </Typography>
            </Grid>
            <Grid item xs={2.5} container justifyContent={'flex-end'}>
              <Radio
                sx={{ padding: '4px' }}
                size="small"
                onChange={() => setSelectedOperator('and')}
                value={'and'}
                checked={selectedOperator === 'and'}
              />
            </Grid>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item xs={7}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#444444',
                  fontSize: '12px',
                }}
              >
                Exclure la sélection
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#444444',
                  fontSize: '11px',
                  fontWeight: 'bold',
                }}
              >
                SANS
              </Typography>
            </Grid>
            <Grid item container xs={2.5} justifyContent={'flex-end'}>
              <Radio
                sx={{ padding: '4px' }}
                size="small"
                onChange={() => setSelectedOperator('not')}
                value={'not'}
                checked={selectedOperator === 'not'}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item container>
        <IconTextField
          inputProps={{
            sx: {
              color: '#444444',
              height: '33px',
              padding: '0 10px',
              fontSize: '13px',
              fontFamily: 'Poppins',
              width: '100%',
            },
          }}
          sx={{
            width: '100%',
          }}
          size="small"
          placeholder={'Rechercher...'}
          iconEnd={<SearchIcon />}
          onInput={e => debounced(e.target.value)}
        />
      </Grid>
      <Grid item container sx={{ margin: '3px 0px' }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            color: '#6860DF',
            fontSize: '11px',
            cursor: 'pointer',
          }}
          onClick={() => initFilters?.[selectedFilterTab]?.setSelected(initFilters?.[selectedFilterTab]?.all)}
        >
          Tout sélectionner
        </Typography>
      </Grid>
      <Grid item container overflow={'auto'} sx={{ height: '175px' }} alignContent={'flex-start'}>
        {filtredElements?.map((item, index) => (
          <Grid key={index} container sx={{ margin: selectedFilterTab === 'categories' ? '2px 0px' : '0px 0px' }}>
            <Grid item container flexWrap={'nowrap'} alignItems={'center'} justifyContent={'space-between'}>
              <Grid item container alignItems={'center'} flexWrap={'nowrap'}>
                <Grid item>
                  <Checkbox
                    size="small"
                    onChange={(_, checked) =>
                      handleSelectItem({ checked, item, filter: initFilters?.[selectedFilterTab] })
                    }
                    checked={initFilters?.[selectedFilterTab]?.selected?.includes(item)}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      color: '#444444',
                      fontSize: '12px',
                    }}
                  >
                    {item}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: '#6C5CE7',
                    fontSize: '9px',
                    paddingRight: '2px',
                    cursor: 'pointer',
                  }}
                  onClick={() => initFilters?.[selectedFilterTab]?.setSelected([item])}
                >
                  Seulement
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
FilterItemsListing.propTypes = {
  menuFilters: PropTypes.object,
  initFilters: PropTypes.object,
  selectedOperator: PropTypes.string,
  setSelectedOperator: PropTypes.func,
  handleSelectItem: PropTypes.func,
}

const StatsAndFilters = ({ stats, format, filters }) => {
  const {
    equivalence,
    openMenu,
    menuAnchorEl,
    handleCloseMenu,
    handleMenuClick,
    menuFilters,
    popover,
    initFilters,
    allSelectedTitles,
    handleSelectItem,
  } = useStatsAndFilters({ format, filters })
  const { totalNumber, EtpNumber, m2Number, equivalenceNumber } = useSpring({
    from: { totalNumber: 0, EtpNumber: 0, m2Number: 0, equivalenceNumber: 0 },
    totalNumber: stats.total,
    EtpNumber: stats.total / 80,
    m2Number: stats.total / 450,
    equivalenceNumber: stats.total / equivalence.divisor,
    delay: 0,
    // config: { mass: 1, tension: 20, friction: 10 },
  })

  return (
    <>
      <Grid item container justifyContent={'center'} sx={{ padding: '0px 10px' }}>
        <Grid
          item
          container
          xs={2.9}
          sx={{
            minWidth: '400px !important',
            height: '100px',
            backgroundColor: '#6B5CE7',
            borderRadius: '8px',
            margin: '5px',
            paddingLeft: '15px',
          }}
          justifyContent={'space-between'}
        >
          <Grid item display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Grid>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 600,
                }}
              >
                Total de vos émissions :
              </Typography>
            </Grid>
            <Grid container alignItems={'center'}>
              <Grid item width={'55%'}>
                <animated.div
                  style={{
                    fontFamily: 'Poppins',
                    color: '#FFFFFF',
                    fontSize: '26px',
                    fontWeight: 600,
                    marginRight: '10px',
                  }}
                >
                  {totalNumber.to(n => Number(n.toFixed(0)).toLocaleString('fr'))}
                </animated.div>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: '#FFFFFF',
                    fontSize: '18px',
                    fontWeight: 'medium',
                  }}
                >
                  tCO₂e
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={'flex'} alignItems={'flex-end'}>
            <EmissionsLightIcon style={{ opacity: 0.2 }} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={2.9}
          sx={{
            minWidth: '400px !important',
            height: '100px',
            backgroundColor: '#FFF',
            border: '1px solid #CED4D9',
            borderRadius: '8px',
            margin: '5px',
            paddingLeft: '15px',
          }}
          justifyContent={'space-between'}
        >
          <Grid item display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Grid>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '15px',
                  fontWeight: 600,
                }}
              >
                Vos émissions sont équivalent à :
              </Typography>
            </Grid>
            <Grid container alignItems={'center'}>
              <animated.div
                style={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '26px',
                  fontWeight: 600,
                  marginRight: '10px',
                }}
              >
                {equivalenceNumber.to(n => Number(n.toFixed(0)).toLocaleString('fr'))}
              </animated.div>
              <Grid>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: '#253243',
                    fontSize: '18px',
                    fontWeight: 'medium',
                  }}
                >
                  {equivalence.unit}
                </Typography>
                {equivalence.subUnit && (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      color: '#253243',
                      fontSize: '14px',
                      fontWeight: 300,
                    }}
                  >
                    {equivalence.subUnit}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={'flex'} alignItems={'flex-end'}>
            <EmissionsIcon style={{ opacity: 0.81 }} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2.9}
          sx={{
            minWidth: '400px !important',
            height: '100px',
            backgroundColor: '#FFF',
            border: '1px solid #CED4D9',
            borderRadius: '8px',
            margin: '5px',
            paddingLeft: '15px',
          }}
          justifyContent={'space-between'}
        >
          <Grid item display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Grid>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '15px',
                  fontWeight: 600,
                }}
              >
                Total émissions par € CA :
              </Typography>
            </Grid>
            <Grid container alignItems={'center'}>
              <animated.div
                style={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '26px',
                  fontWeight: 600,
                  marginRight: '10px',
                }}
              >
                {EtpNumber.to(n => Number(n.toFixed(0)).toLocaleString('fr'))}
              </animated.div>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '18px',
                  fontWeight: 'medium',
                }}
              >
                gCO₂e/€ CA
              </Typography>
            </Grid>
          </Grid>
          <Grid item display={'flex'} alignItems={'flex-end'}>
            <EmissionsIcon style={{ opacity: 0.81 }} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={2.9}
          sx={{
            minWidth: '400px !important',
            height: '100px',
            backgroundColor: '#FFF',
            border: '1px solid #CED4D9',
            borderRadius: '8px',
            margin: '5px',
            paddingLeft: '15px',
          }}
          justifyContent={'space-between'}
        >
          <Grid item display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Grid>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '15px',
                  fontWeight: 600,
                }}
              >
                Total émissions par ETP :
              </Typography>
            </Grid>
            <Grid container alignItems={'center'}>
              <animated.div
                style={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '26px',
                  fontWeight: 600,
                  marginRight: '10px',
                }}
              >
                {m2Number.to(n => Number(n.toFixed(0)).toLocaleString('fr'))}
              </animated.div>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '18px',
                  fontWeight: 'medium',
                }}
              >
                KgCO₂e/ETP
              </Typography>
            </Grid>
          </Grid>
          <Grid item display={'flex'} alignItems={'flex-end'}>
            <EmissionsIcon style={{ opacity: 0.81 }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container flexWrap={'nowrap'} alignItems={'center'} sx={{ margin: '10px 0px', padding: '0px 25px' }}>
        <Grid item>
          <Button
            sx={{
              background: '#FFFFFF',
              border: '1px solid #AFAFAF',
              borderRadius: '5px',
              height: '40px',
              minWidth: '165px',
              fontFamily: 'Poppins',
              fontSize: '16px',
              textTransform: 'none',
              color: '#253243',
              fontWeight: 'medium',
              letterSpacing: '0px',
              marginRight: '10px',
            }}
            startIcon={<FiltersIcon style={{ marginRight: '12px' }} />}
            onClick={e => {
              handleMenuClick(e)
            }}
          >
            Filtres
          </Button>
          <Menu
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <MenuItem
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                menuFilters.setOpenOrgaFilter(true)
                popover.handleOpenPopover(e)
              }}
            >
              <Grid container justifyContent={'space-between'}>
                <Grid item display={'flex'} alignItems={'center'}>
                  <OrganisationIcon />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      color: '#444444',
                      fontWeight: 'medium',
                      letterSpacing: '0.13px',
                      margin: '0px 12px',
                    }}
                  >
                    Organisation
                  </Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowRightIcon style={{ color: '#444444' }} />
                </Grid>
              </Grid>
            </MenuItem>
            {format === 'co2Recap' && (
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  menuFilters.setOpenLabelFilter(true)
                  popover.handleOpenPopover(e)
                }}
              >
                <Grid container justifyContent={'space-between'}>
                  <Grid item display={'flex'} alignItems={'center'}>
                    <LabelIcon style={{ width: '19px', marginRight: '3px' }} />
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        color: '#444444',
                        fontWeight: 'medium',
                        letterSpacing: '0.13px',
                        margin: '0px 10px',
                      }}
                    >
                      Label
                    </Typography>
                  </Grid>
                  <Grid item>
                    <KeyboardArrowRightIcon style={{ color: '#444444' }} />
                  </Grid>
                </Grid>
              </MenuItem>
            )}
            <MenuItem
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                format === 'co2Recap'
                  ? menuFilters.setOpenPostFilter(true)
                  : format === 'ghg'
                  ? menuFilters.setOpenSopeFilter(true)
                  : menuFilters.setOpenCategoryFilter(true)
                popover.handleOpenPopover(e)
              }}
            >
              <Grid container justifyContent={'space-between'}>
                <Grid item display={'flex'} alignItems={'center'}>
                  <PostIcon style={{ width: '25px' }} />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      color: '#444444',
                      fontWeight: 'medium',
                      letterSpacing: '0.13px',
                      margin: '0px 10px',
                    }}
                  >
                    {format === 'co2Recap' ? 'Poste d’émissions' : format === 'ghg' ? 'Scope' : 'Catégorie'}
                  </Typography>
                </Grid>
                <Grid item>
                  <KeyboardArrowRightIcon style={{ color: '#444444' }} />
                </Grid>
              </Grid>
            </MenuItem>
          </Menu>
          <Popover
            id={popover.popoverId}
            open={popover.openPopover}
            anchorEl={popover.popoverAnchorEl}
            onClose={popover.handleClosePopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            sx={{ marginLeft: '5px' }}
            elevation={5}
          >
            <FilterItemsListing
              initFilters={initFilters}
              selectedOperator={filters.selectedOperator}
              setSelectedOperator={filters.setSelectedOperator}
              menuFilters={menuFilters}
              handleSelectItem={handleSelectItem}
            />
          </Popover>
        </Grid>
        <Grid
          item
          container
          justifyContent={'space-between'}
          flexWrap={'nowrap'}
          sx={{
            border: '1px solid #CED4D9',
            backgroundColor: '#FFF',
            borderRadius: '6px',
            width: '100%',
            minHeight: '40px',
            maxHeight: '80px',
            padding: '3px 10px',
          }}
        >
          <Grid item overflow={'auto'} container>
            {Object.keys(allSelectedTitles)?.map(
              (item, index) =>
                initFilters?.[item]?.all?.length === initFilters?.[item]?.selected?.length && (
                  <Grid
                    key={index}
                    item
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-around'}
                    sx={{
                      backgroundColor: '#F4F4F4',
                      borderRadius: '6px',
                      minWidth: '144px',
                      minHeight: '28px',
                      margin: '2px 5px',
                      padding: '0px 7px',
                      border: '1px solid #E0E0E0',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        color: '#444444',
                        fontSize: '13px',
                        letterSpacing: '0.11px',
                      }}
                    >
                      {allSelectedTitles[item]}
                    </Typography>
                  </Grid>
                ),
            )}
            {Object.keys(allSelectedTitles)?.map(
              item =>
                initFilters?.[item]?.all?.length !== initFilters?.[item]?.selected?.length &&
                initFilters?.[item]?.selected?.length > 0 &&
                initFilters?.[item]?.selected?.map((element, index) => (
                  <Grid
                    key={index}
                    item
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{
                      backgroundColor: '#F4F4F4',
                      borderRadius: '6px',
                      minWidth: '144px',
                      minHeight: '28px',
                      margin: '2px 5px',
                      padding: '0px 7px',
                      border: '1px solid #E0E0E0',
                    }}
                  >
                    <Grid item container>
                      {initFilters?.[item]?.icon}
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          color: '#444444',
                          fontSize: '13px',
                          letterSpacing: '0.11px',
                          marginLeft: '5px',
                        }}
                      >
                        {element}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleSelectItem({ checked: false, item: element, filter: initFilters?.[item] })}
                        sx={{ padding: '1px', marginLeft: '2px', marginBottom: '2px' }}
                        size="small"
                      >
                        <CloseIcon fontSize="8px" style={{ color: '#EC5E57' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )),
            )}
          </Grid>
          <Grid item display={'flex'} alignItems={'center'}>
            <Button
              sx={{
                background: '#FFF4F4',
                border: '1px solid #E0E0E0',
                borderRadius: '5px',
                height: '28px',
                width: '100px',
                fontFamily: 'Poppins',
                fontSize: '14px',
                textTransform: 'none',
                color: '#FF5150',
                fontWeight: 'medium',
                letterSpacing: '0px',
                marginLeft: '5px',
              }}
              onClick={() => filters.resetFilters()}
            >
              tout effacer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
StatsAndFilters.propTypes = {
  stats: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
  filters: PropTypes.object,
}
export default StatsAndFilters
