import React from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { format } from 'd3'

const CollapseRow = ({ row }) => {
  const formatter = format('.3~s')

  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow
        sx={{ cursor: 'pointer', height: '40px', '& > *': { borderBottom: 'unset' } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell
          align={'left'}
          sx={{
            padding: '0px 5px',
          }}
        >
          <Grid container alignContent={'center'}>
            <Grid container justifyContent={'center'} sx={{ width: '40px', marginRight: '5px' }}>
              {row.icon}
            </Grid>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: '#444444',
                fontSize: '16px',
                fontWeight: 'medium',
                letterSpacing: 0.31,
              }}
            >
              {row?.name}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell
          sx={{
            padding: '0px 16px',
            fontFamily: 'Poppins',
            color: '#444444',
            fontSize: '16px',
            fontWeight: 'medium',
            letterSpacing: 0.31,
          }}
          align="center"
        >
          {row?.uncertainty?.toFixed(0)}
        </TableCell>
        <TableCell
          sx={{
            padding: '0px 16px',
            fontFamily: 'Poppins',
            color: '#444444',
            fontSize: '16px',
            fontWeight: 'medium',
            letterSpacing: 0.31,
          }}
          align="center"
        >
          {row?.total === 0
            ? 0
            : row?.total / 1000 >= 1
            ? formatter(row?.total / 1000)
            : (row?.total / 1000).toFixed(3)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container sx={{ backgroundColor: '#EFEFEF', padding: '20px 35px' }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontFamily: 'Poppins',
                          color: '#253243',
                          fontSize: '12px',
                          fontWeight: 600,
                        }}
                      >
                        Sous postes
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: 'Poppins',
                          color: '#253243',
                          fontSize: '12px',
                          fontWeight: 600,
                        }}
                        align="center"
                        width={'250px'}
                      >
                        Incertitude (%)
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: 'Poppins',
                          color: '#253243',
                          fontSize: '12px',
                          fontWeight: 600,
                        }}
                        width={'250px'}
                        align="center"
                      >
                        Total (tCO₂e)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.subPosts?.map((subPost, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          sx={{
                            fontFamily: 'Poppins',
                            color: '#253243',
                            fontSize: '14px',
                          }}
                          component="th"
                          scope="row"
                        >
                          {subPost?.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'Poppins',
                            color: '#253243',
                            fontSize: '14px',
                          }}
                          width={'250px'}
                          align="center"
                        >
                          {(subPost?.uncertainty * 100)?.toFixed(0)}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: 'Poppins',
                            color: '#253243',
                            fontSize: '14px',
                          }}
                          width={'250px'}
                          align="center"
                        >
                          {subPost?.total === 0
                            ? 0
                            : subPost?.total / 1000 >= 1
                            ? formatter(subPost?.total / 1000)
                            : (subPost?.total / 1000).toFixed(3)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
CollapseRow.propTypes = {
  row: PropTypes.object.isRequired,
}

const RecapCo2PostsTable = ({ data }) => {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ height: '40px' }}>
            <TableCell
              align="left"
              sx={{
                padding: '0px 16px',
              }}
            >
              <Grid container alignContent={'center'}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: '#253243',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  #
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: '#253243',
                    fontSize: '12px',
                    fontWeight: 600,
                    marginLeft: { sm: '50px', md: '250px' },
                  }}
                >
                  Postes d’émissions
                </Typography>
              </Grid>
            </TableCell>

            <TableCell
              sx={{
                padding: '0px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 600,
              }}
              align="center"
            >
              Incertitude (%)
            </TableCell>
            <TableCell
              sx={{
                padding: '0px 16px',
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 600,
              }}
              align="center"
            >
              Total (tCO₂e)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <CollapseRow key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

RecapCo2PostsTable.propTypes = {
  data: PropTypes.array,
}

export default RecapCo2PostsTable
