import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { useGhgPage } from './GhgPageHook'
import GhgTreeMap from './GhgTreeMap/GhgTreeMap'
import BegesPostsTable from '../BegesPage/BegesPostsTable/BegesPostsTable'
import BarChart from './RecapCo2PostsChart/RecapCo2PostsChart'

const GhgPage = ({ filtredGhgData, filtredScopes }) => {
  const { scopesPosts, treeMapData, barChartData } = useGhgPage({
    filtredGhgData,
    filtredScopes,
  })

  return (
    <Grid container sx={{ padding: '0px 15px' }}>
      <Grid item xs={12} lg={4.5} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            x: [-1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '574px', padding: '10px' }}>
            <Grid sx={{ paddingLeft: '10px' }}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Analyse des émissions par scope :
              </Typography>
            </Grid>
            <Grid marginTop={'50px'}>
              <BarChart data={barChartData} />
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
      <Grid item xs={12} lg={7.5} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            x: [1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid
            container
            sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', minHeight: '574px', padding: '15px' }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Analyse des émissions décomposées par scope :
              </Typography>
            </Grid>
            <Grid item container sx={{ width: '100%', height: '500px' }}>
              {treeMapData?.map((scope, index) => {
                return scope.total !== 0 ? (
                  <Grid
                    key={index}
                    container
                    justifyContent={'center'}
                    xs={scope?.percentage}
                    item
                    sx={{ height: '480px' }}
                  >
                    <GhgTreeMap data={scope} />
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        color: '#253243',
                        fontSize: '12px',
                        fontWeight: 600,
                      }}
                    >
                      {scope?.name}
                    </Typography>
                  </Grid>
                ) : null
              })}
            </Grid>
          </Grid>
        </motion.div>
      </Grid>

      <Grid item xs={12} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            y: [1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid
            sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '100%', padding: '0px 10px 10px 10px' }}
          >
            <BegesPostsTable data={scopesPosts} />
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
  )
}
GhgPage.propTypes = {
  filtredGhgData: PropTypes.array.isRequired,
  filtredScopes: PropTypes.array.isRequired,
}
export default GhgPage
