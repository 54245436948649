import React, { useEffect, useState } from 'react'

import { CO2RECAP, BEGESV5, GHG } from '../../utils/constants/data'
import { ReactComponent as OrganisationIcon } from '../../utils/icons/OrganisationIcon.svg'
import { ReactComponent as LabelIcon } from '../../utils/icons/LabelIcon.svg'
import { ReactComponent as PostIcon } from '../../utils/icons/PostIcon.svg'

export const useStatsAndFilters = ({ format, filters }) => {
  const appDomain = window?.location?.pathname
  const EQUIVALENCY = {
    '/transport': {
      divisor: 1, // en tonnes
      unit: 'Paris/New York',
      subUnit: 'Aller/retour',
    },
    '/agriculture': {
      divisor: 0.007,
      unit: 'Repas ',
      subUnit: 'avec du bœuf',
    },
    '/industrie': {
      divisor: 0.907,
      unit: 'Armoire',
    },
    '/numerique': {
      divisor: 0.029,
      unit: 'Smartphone',
    },
    '/': {
      divisor: 1,
      unit: 'Paris/New York',
      subUnit: 'Aller/retour',
    },
  }

  const [openOrgaFilter, setOpenOrgaFilter] = useState(false)
  const [openLabelFilter, setOpenLabelFilter] = useState(false)
  const [openPostFilter, setOpenPostFilter] = useState(false)
  const [openCategoryFilter, setOpenCategoryFilter] = useState(false)
  const [openScopeFilter, setOpenSopeFilter] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)

  const openMenu = Boolean(menuAnchorEl)
  const openPopover = Boolean(popoverAnchorEl)
  const popoverId = openPopover ? 'simple-popover' : undefined

  const handleMenuClick = event => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const handleOpenPopover = event => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setPopoverAnchorEl(null)
  }

  useEffect(() => {
    if (!openPopover) {
      setTimeout(() => {
        setOpenCategoryFilter(false)
        setOpenLabelFilter(false)
        setOpenOrgaFilter(false)
        setOpenPostFilter(false)
        setOpenSopeFilter(false)
      }, 200)
    }
  }, [openPopover])

  const initFilters = format => {
    switch (format) {
      case 'co2Recap':
        return {
          organisations: {
            all: [...new Set(CO2RECAP.map(item => item.orga.name))],
            selected: filters.selectedOrganisations,
            setSelected: filters.setSelectedOrganisations,
            icon: <OrganisationIcon style={{ width: '16px' }} />,
          },
          labels: {
            all: [
              'Données sans labels',
              ...new Set(CO2RECAP.reduce((prev, curr) => (curr.labels.length ? [...prev, ...curr.labels] : prev), [])),
            ],
            selected: filters.selectedLabels,
            setSelected: filters.setSelectedLabels,
            icon: <LabelIcon style={{ width: '16px' }} />,
          },
          posts: {
            all: [...new Set(CO2RECAP.map(item => item.poste.name))],
            selected: filters.selectedPosts,
            setSelected: filters.setSelectedPosts,
            icon: <PostIcon style={{ width: '20px' }} />,
          },
        }
      case 'begesV5':
        return {
          organisations: {
            all: [...new Set(BEGESV5.map(item => item.orga.name))],
            selected: filters.selectedOrganisations,
            setSelected: filters.setSelectedOrganisations,
            icon: <OrganisationIcon style={{ width: '16px' }} />,
          },
          categories: {
            all: [...new Set(BEGESV5.map(item => item.category.name))],
            selected: filters.selectedCategories,
            setSelected: filters.setSelectedCategories,
            icon: <PostIcon style={{ width: '20px' }} />,
          },
        }
      case 'ghg':
        return {
          organisations: {
            all: [...new Set(GHG.map(item => item.orga.name))],
            selected: filters.selectedOrganisations,
            setSelected: filters.setSelectedOrganisations,
            icon: <OrganisationIcon style={{ width: '16px' }} />,
          },
          scopes: {
            all: [...new Set(GHG.map(item => item.Scope.name))],
            selected: filters.selectedScopes,
            setSelected: filters.setSelectedScopes,
            icon: <PostIcon style={{ width: '20px' }} />,
          },
        }
    }
  }
  const co2RacapFiltersTitles = {
    posts: 'Tous les postes',
    labels: 'Tous les labels',
    organisations: 'Tous les organisations',
  }
  const begesFiltersTitles = {
    organisations: 'Tous les organisations',
    categories: 'Tous les catégories',
  }
  const ghgFiltersTitles = {
    organisations: 'Tous les organisations',
    scopes: 'Tous les scopes',
  }
  const allSelectedTitles = () => {
    switch (format) {
      case 'co2Recap':
        return co2RacapFiltersTitles
      case 'begesV5':
        return begesFiltersTitles
      case 'ghg':
        return ghgFiltersTitles
    }
  }

  const handleSelectItem = ({ checked, item, filter }) => {
    if (checked) {
      filter?.setSelected([...(filter?.selected ?? []), item] ?? [])
    } else {
      filter?.setSelected([...(filter?.selected?.filter(el => el !== item) ?? [])])
    }
  }
  return {
    equivalence: EQUIVALENCY[appDomain] ?? {
      divisor: 1,
      unit: 'Paris/New York',
      subUnit: 'Aller/retour',
    },
    openMenu,
    menuAnchorEl,
    handleCloseMenu,
    handleMenuClick,
    menuFilters: {
      openLabelFilter,
      openOrgaFilter,
      openPostFilter,
      openScopeFilter,
      openCategoryFilter,
      setOpenCategoryFilter,
      setOpenLabelFilter,
      setOpenOrgaFilter,
      setOpenPostFilter,
      setOpenSopeFilter,
    },
    popover: {
      popoverId,
      openPopover,
      popoverAnchorEl,
      handleClosePopover,
      handleOpenPopover,
    },
    initFilters: initFilters(format),
    allSelectedTitles: allSelectedTitles(),
    handleSelectItem,
  }
}
