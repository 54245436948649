import { useEffect, useState } from 'react'
import { CO2RECAP, BEGESV5, GHG } from '../../utils/constants/data'

export const useApp = () => {
  const [selectedTab, setSelectedTab] = useState(1)
  const isSelectedTab = tabValue => tabValue === selectedTab

  const [selectedPosts, setSelectedPosts] = useState()
  const [selectedOrganisations, setSelectedOrganisations] = useState()
  const [selectedLabels, setSelectedLabels] = useState()
  const [selectedCategories, setSelectedCategories] = useState()
  const [selectedScopes, setSelectedScopes] = useState()

  const [selectedOperator, setSelectedOperator] = useState('or')

  const hasLabels = (labels, item) => {
    if (labels?.includes('Données sans labels')) {
      if (selectedOperator === 'or') {
        return labels?.some(label => item?.labels?.includes(label) || item?.labels?.length === 0)
      } else if (selectedOperator === 'not') {
        return item?.labels?.length !== 0 && labels?.every(label => !item?.labels?.includes(label))
      } else return false
    } else if (selectedOperator === 'or') {
      return labels?.length === 0 ? true : labels?.some(label => item?.labels?.includes(label))
    } else if (selectedOperator === 'and') {
      return labels?.length === 0 ? false : labels?.every(label => item?.labels?.includes(label))
    } else if (selectedOperator === 'not') {
      return labels?.every(label => !item?.labels?.includes(label))
    }
  }
  const hasOrganisations = (organizations, item) => organizations?.some(orga => item?.orga?.name === orga)
  const hasPosts = (posts, item) => posts?.some(post => item?.poste?.name === post)
  const hasCategory = (categories, item) => categories?.some(category => item?.category?.name === category)
  const hasScopes = (scopes, item) => scopes?.some(scope => item?.Scope?.name === scope)

  //Co2 Recap

  const filtredCO2RECAPData = isSelectedTab(1)
    ? CO2RECAP.filter(item => {
        return (
          hasLabels(selectedLabels, item) &&
          hasOrganisations(selectedOrganisations, item) &&
          hasPosts(selectedPosts, item)
        )
      })
    : []

  //filters to display
  const filtredPosts = [...new Set(filtredCO2RECAPData.map(item => item.poste.name))]

  const totalCo2RacapResult = CO2RECAP.reduce((prev, current) => prev + current.result, 0) / 1000

  //Beges V5

  const filtredBegesData = isSelectedTab(2)
    ? BEGESV5.filter(item => {
        return hasCategory(selectedCategories, item) && hasOrganisations(selectedOrganisations, item)
      })
    : []
  //filters to display
  const begesFiltredOraganisations = [...new Set(filtredBegesData.map(item => item.orga.name))]
  const begesFiltredCategories = [...new Set(filtredBegesData.map(item => item.category.name))]

  const begesTotalResult = BEGESV5.reduce((prev, current) => prev + current.emission, 0)

  //Ghg

  const filtredGhgData = isSelectedTab(3)
    ? GHG.filter(item => {
        return hasScopes(selectedScopes, item) && hasOrganisations(selectedOrganisations, item)
      })
    : []

  //filters to display
  const ghgFiltredScopes = [...new Set(filtredGhgData.map(item => item.Scope.name))]

  const ghgTotalResult = GHG.reduce((prev, current) => prev + current.emission, 0)

  const co2RacapStats = {
    total: totalCo2RacapResult,
    totalEtp: 454456,
    totalM2: 120141,
  }
  const begesStats = {
    total: begesTotalResult,
    totalEtp: 723441,
    totalM2: 448014,
  }
  const ghgStats = {
    total: ghgTotalResult,
    totalEtp: 124921,
    totalM2: 471170,
  }
  const resetFilters = () => {
    if (selectedTab === 1) {
      setSelectedPosts([...new Set(CO2RECAP.map(item => item.poste.name))])
      setSelectedLabels([])
      setSelectedOrganisations([...new Set(CO2RECAP.map(item => item.orga.name))])
    } else if (selectedTab === 2) {
      setSelectedCategories([...new Set(BEGESV5.map(item => item.category.name))])
      setSelectedOrganisations([...new Set(BEGESV5.map(item => item.orga.name))])
    } else {
      setSelectedScopes([...new Set(GHG.map(item => item.Scope.name))])
      setSelectedOrganisations([...new Set(GHG.map(item => item.orga.name))])
    }
  }
  useEffect(() => {
    resetFilters()
  }, [selectedTab])

  const initializeFilters = () => {
    setSelectedPosts()
    setSelectedCategories()
    setSelectedLabels()
    setSelectedScopes()
    setSelectedOrganisations()
  }

  return {
    selectedTab,
    setSelectedTab,
    isSelectedTab,
    filtredCO2RECAPData,
    filtredPosts,
    begesData: {
      filtredBegesData,
      begesFiltredOraganisations,
      begesFiltredCategories,
    },
    ghgData: {
      filtredGhgData,
      ghgFiltredScopes,
    },
    stats: {
      co2RacapStats,
      begesStats,
      ghgStats,
    },
    filters: {
      setSelectedPosts,
      setSelectedCategories,
      setSelectedLabels,
      setSelectedScopes,
      setSelectedOrganisations,
      selectedCategories,
      selectedLabels,
      selectedOrganisations,
      selectedPosts,
      selectedScopes,
      selectedOperator,
      setSelectedOperator,
      initializeFilters,
      resetFilters,
    },
  }
}
