import { GHG } from '../../utils/constants/data'

export const useGhgPage = ({ filtredGhgData, filtredScopes }) => {
  const totalResult = filtredGhgData.reduce((prev, current) => prev + current.emission, 0)

  const allGhgScopes = [...new Set(GHG.map(item => item.Scope.name))] ?? []

  const scopePercentageData = filtredScopes.map(scopeName => {
    let countPost = {}

    const scopeTotalResult = filtredGhgData.reduce((prev, current) => {
      const postName = current?.poste?.name
      countPost = { ...countPost, [postName]: (countPost[postName] ?? 0) + 1 }
      return current.Scope.name === scopeName ? prev + current.emission : prev
    }, 0)

    const posts = filtredGhgData.reduce((prev, current) => {
      const posteName = current?.poste?.name
      const posteResult = current?.emission
      const posteUncertainty = current?.uncertainty
      const posteId = current?.poste?.id !== 'nan' ? current?.poste?.id : ''
      return current.Scope.name === scopeName
        ? {
            ...prev,
            [posteName]: {
              ...prev[posteName],
              emission: (prev[posteName]?.emission ?? 0) + posteResult,
              uncertainty: (prev[posteName]?.uncertainty ?? 0) + posteUncertainty / countPost[posteName],
              id: posteId,
              name: posteName,
            },
          }
        : prev
    }, {})

    return {
      name: scopeName,
      percentage: (scopeTotalResult / totalResult) * 100,
      total: scopeTotalResult,
      uncertainty:
        Object.values(posts ?? {})?.reduce((prev, current) => prev + current.uncertainty, 0) /
        Object.keys(posts ?? {})?.length,
      posts: posts,
    }
  })

  const formattedScopesPercentages = allGhgScopes.map(item => {
    const scopeExist = scopePercentageData?.find(scope => scope.name === item)
    return (
      scopeExist ?? {
        name: item,
        percentage: 0,
        total: 0,
        uncertainty: 0,
        posts: [],
      }
    )
  })

  const getWidthPercentage = name => {
    switch (name) {
      case 'Scope 1':
        return 1.5
      case 'Scope 2':
        return 1
      case 'Scope 3 Amont':
        return 7
      case 'Scope 3 Aval':
        return 2.5
      default:
        return 3
    }
  }

  const treeMapData = formattedScopesPercentages.map((item, index) => ({
    name: item?.name,
    total: item?.total,
    id: index,
    children: Object.values(item?.posts ?? {})
      ?.filter(item => item.emission > 0)
      ?.sort((a, b) => b?.emission - a?.emission),
    percentage: item?.total !== 0 ? getWidthPercentage(item?.name) : 0,
  }))

  const barChartData = formattedScopesPercentages?.map((item, index) => ({
    name: item?.name,
    value: item?.total,
    id: index,
  }))

  // new  uncertainty calculation

  const allPosts = [...new Set(filtredGhgData.map(item => item.poste.name))]
  const postsUncertainty = allPosts.reduce((prev, current) => {
    let coefficient = 0
    let total = 0
    filtredGhgData.forEach(item => {
      if (item.poste.name === current) {
        total += item.emission
        coefficient += Math.pow(item.uncertainty, 2)
      }
    })
    return { ...prev, [current]: { total, uncertainty: total !== 0 ? (Math.sqrt(coefficient) / total) * 100 : 0 } }
  }, {})

  const allScopes = [...new Set(filtredGhgData.map(item => item.Scope.name))]

  const scopesPosts = allScopes.reduce((prev, current) => {
    let posts = []
    let coefficient = 0
    let total = 0
    filtredGhgData.forEach(item => {
      const posteId = item?.poste?.id
      const postName = item.poste.name
      if (item.Scope.name === current && !posts.map(post => post.name).includes(postName)) {
        posts.push({ id: posteId, name: postName, ...postsUncertainty[postName] })
        total += postsUncertainty[postName]?.total
        coefficient += Math.pow((postsUncertainty[postName]?.uncertainty / 100) * postsUncertainty[postName].total, 2)
      }
    })
    return [
      ...prev,
      { name: current, posts, total, uncertainty: total !== 0 ? (Math.sqrt(coefficient) / total) * 100 : 0 },
    ]
  }, [])

  return {
    treeMapData,
    barChartData,
    scopesPosts,
  }
}
