import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { useBegesPage } from './BegesPageHook'
import BegesCategoryaChart from './BegesCategoryaChart/BegesCategoryaChart'
import BegesOrgaPieChart from './BegesOrgaPieChart/BegesOrgaPieChart'
import BegesPostsTable from './BegesPostsTable/BegesPostsTable'

const BegesPage = ({ filtredBegesData, filtredOraganisations, filtredCategories }) => {
  const { barsData, organisationData, categoriesPosts } = useBegesPage({
    filtredBegesData,
    filtredOraganisations,
    filtredCategories,
  })
  return (
    <Grid container sx={{ padding: '0px 15px' }}>
      <Grid item xs={12} lg={7.5} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            x: [-1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid
            alignContent={'flex-end'}
            sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '574px', padding: '15px' }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              Émissions par catégorie d’émissions :
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 300,
              }}
            >
              Visualisez les catégories d’émissions les plus importantes de votre bilan carbone
            </Typography>
            <Grid overflow={'auto'}>
              <BegesCategoryaChart categories={Object.keys(barsData)} values={Object.values(barsData)} />
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
      <Grid item xs={12} lg={4.5} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            x: [1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '574px', padding: '10px' }}>
            <Grid sx={{ paddingLeft: '10px' }}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Émissions par organisation :
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '12px',
                  fontWeight: 300,
                }}
              >
                Découvrez la quantité de TCO₂e émise sur chacune de vos organisations
              </Typography>
            </Grid>
            <BegesOrgaPieChart data={organisationData} />
          </Grid>
        </motion.div>
      </Grid>
      <Grid item xs={12} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            y: [1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid
            sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '100%', padding: '0px 10px 10px 10px' }}
          >
            <BegesPostsTable data={categoriesPosts} />
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
  )
}
BegesPage.propTypes = {
  filtredBegesData: PropTypes.array.isRequired,
  filtredOraganisations: PropTypes.array.isRequired,
  filtredCategories: PropTypes.array.isRequired,
}
export default BegesPage
