import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { useCo2RacapPage } from './Co2RacapPageHook'
import RecapCo2OrgaChart from './RecapCo2OrgaChart/RecapCo2OrgaChart'
import RecapCo2PostsChart from './RecapCo2PostsChart/RecapCo2PostsChart'
import RecapCo2PostsTable from './RecapCo2PostsTable/RecapCo2PostsTable'

const Co2RacapPage = ({ filtredCO2RECAPData, filtredPosts }) => {
  const { barsData, postPercentageData, sortedpostsSubposts } = useCo2RacapPage({
    filtredCO2RECAPData,
    filtredPosts,
  })

  return (
    <Grid container sx={{ padding: '0px 15px' }}>
      <Grid item xs={12} lg={7.5} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            x: [-1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid
            alignContent={'flex-end'}
            sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '574px', padding: '15px' }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              Organisations plus émettrices :
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: '#253243',
                fontSize: '12px',
                fontWeight: 300,
              }}
            >
              Découvrez la quantité de TCO₂e émise sur chacune de vos organisations
            </Typography>
            <Grid overflow={'auto'}>
              <RecapCo2OrgaChart organisations={Object.keys(barsData)} values={Object.values(barsData)} />
            </Grid>
          </Grid>
        </motion.div>
      </Grid>

      <Grid item xs={12} lg={4.5} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            x: [1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '574px', padding: '10px' }}>
            <Grid sx={{ paddingLeft: '10px' }}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Mes postes les plus emetteurs
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  color: '#253243',
                  fontSize: '12px',
                  fontWeight: 300,
                }}
              >
                Visualisez les postes d’émissions les plus importants de votre bilan carbone
              </Typography>
            </Grid>
            <RecapCo2PostsChart data={postPercentageData} />
          </Grid>
        </motion.div>
      </Grid>
      <Grid item xs={12} sx={{ padding: '10px' }}>
        <motion.div
          animate={{
            y: [1000, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
            ease: 'easeOut',
          }}
        >
          <Grid
            sx={{ border: '1px solid #CED4D9', backgroundColor: '#FFF', height: '100%', padding: '0px 10px 10px 10px' }}
          >
            <RecapCo2PostsTable data={sortedpostsSubposts} />
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
  )
}
Co2RacapPage.propTypes = {
  filtredCO2RECAPData: PropTypes.array.isRequired,
  filtredPosts: PropTypes.array.isRequired,
}
export default Co2RacapPage
